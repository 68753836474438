import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton, Button, Heading, Text } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const helpSteps = [
  { title: "Step 1: Add new state to the flowdiagram", img: "/step1_codegeneration.jpeg", description: "Click on blue add button to create a new state" },
  { title: "Step 2: Click on the state", img: "/step2_codegeneration.jpeg", description: "Click on the state to edit the state" },
  { title: "Step 3: Click edit button", img: "/step3_codegeneration.jpeg", description: "Click blue edit button to modify your node" },
  { title: "Step 4: Freely modify", img: "/step4_codegeneration.jpeg", description: "Freely modify your inputs, outputs, alarms, description" },
  { title: "Step 5: Save", img: "/step5_codegeneration.jpeg", description: "Click on green save button to save your changes" },
  { title: "Step 6: Generate Code", img: "/step6_codegeneration.jpeg", description: "Select a robot brand and generate the code automatically" },
  { title: "Step 7: View Code", img: "/step7_codegeneration.jpeg", description: "Visualize and edit your generated code" },

];


const HelpCarouselModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(5px)" />
      <ModalContent bg="vscode.sidebar">
        <ModalHeader>How to Generate Code</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Swiper modules={[Navigation, Pagination]} navigation pagination={{ clickable: true }} className="w-full">
            {helpSteps.map((step, index) => (
              <SwiperSlide key={index} className="flex flex-col items-center text-center ">
                <Heading>{step.title}</Heading>
                <img src={step.img} alt={step.title} className="w-full h-48 object-contain my-4" />
              </SwiperSlide>
            ))}
          </Swiper>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" color="white" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default HelpCarouselModal;
