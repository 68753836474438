import React, {useEffect} from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import AdminDashboard from "./pages/admin/AdminDashboard";
import PremiumHome from "./pages/premium/PremiumHome";
import BaseHome from "./pages/base/BaseHome";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Signup from "./pages/Signup";
import ChatPage from "./pages/premium/ChatPage";
import IOConfigPage from "./pages/premium/IOConfigPage";
import CodeEditorPage from "./pages/premium/CodeEditorPage";
import DocumentationPage from "./pages/premium/DocumentationPage";
import AuthProvider from "./hooks/AuthProvider";
import ProtectedRoute from "./ProtectedRoute";
import GraphVisualization from "./pages/premium/testGraph";

const AppRoutes = () => {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
};


const AppContent = () => {
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("token");

  let user;
  try {
    user = JSON.parse(localStorage.getItem("user") || "{}");
  } catch (error) {
    console.error("Error parsing user from localStorage:", error);
    user = {};
  }

  useEffect(() => {
    if (!isAuthenticated && window.location.pathname !== "/login") {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);



  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/documentation/graph" element={<GraphVisualization />} />
      <Route
        path="/admin-dashboard"
        element={
          <ProtectedRoute roles={["admin"]}>
            <AdminDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/premium-home"
        element={
          <ProtectedRoute roles={["premium"]}>
            <PremiumHome user={user} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/premium/chat"
        element={
          <ProtectedRoute roles={["premium"]}>
            <ChatPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/premium/io-config"
        element={
          <ProtectedRoute roles={["premium"]}>
            <IOConfigPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/premium/code-editor"
        element={
          <ProtectedRoute roles={["premium"]}>
            <CodeEditorPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/premium/documentation"
        element={
          <ProtectedRoute roles={["premium"]}>
            <DocumentationPage />
          </ProtectedRoute>
        }
      />

      <Route path="/base-home" element={<BaseHome user={user} />} />
      <Route path="*" element={<LoginRedirect />} />
    </Routes>
  );
};

const LoginRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/base-home", { replace: true });
  }, [navigate]);

  return null;
};

export default AppRoutes;


