import React from "react";
import { motion } from "framer-motion";
import { Box, Input, Flex, Wrap, WrapItem, Button, useMediaQuery } from "@chakra-ui/react";

const InitialMessagePrompt = ({ letters, userMessage, setUserMessage, handleSendMessage, isLoading, predefinedQuestions }) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)"); // Detect mobile screens

  return (
    <Flex
      height={isMobile ? "70vh" : "80vh"} // Reduce height on mobile
      justify="center"
      align="center"
      flexDirection="column"
      mt={4}
      bg="vscode.sidebar"
      width={isMobile ? "95%" : "90%"} // Use more width on mobile
      borderRadius="xl"
      p={isMobile ? 4 : 6} // Add padding for mobile
    >
      <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
        <Box
          textAlign="center"
          mb={isMobile ? 2 : 4} // Reduce margin on mobile
          fontSize={isMobile ? "lg" : "xl"} // Reduce text size for mobile
          fontWeight="bold"
          color="white"
        >
          {letters.map((letter, index) => (
            <motion.span
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.05,
                delay: index * 0.05,
              }}
            >
              {letter}
            </motion.span>
          ))}
        </Box>
      </motion.div>
      
      {/* Input Box and Send Button */}
      <Flex width={isMobile ? "100%" : "80%"} mb={isMobile ? 2 : 4} flexDirection={isMobile ? "column" : "row"}>
        <Input
          placeholder="Type your message..."
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !isLoading) handleSendMessage();
          }}
          isDisabled={isLoading}
          textAlign="center"
          flex="1"
          fontSize={isMobile ? "sm" : "md"} // Reduce font size for mobile
          p={isMobile ? 2 : 3} // Reduce padding for mobile
          mb={isMobile ? 2 : 0} // Add space below input in mobile
        />
        <Button
          ml={isMobile ? 0 : 2}
          bg="xelerit.lightBlue"
          color="white"
          onClick={() => handleSendMessage()}
          isLoading={isLoading}
          fontSize={isMobile ? "sm" : "md"} // Adjust font size
          p={isMobile ? 2 : 3} // Adjust padding
          width={isMobile ? "100%" : "auto"} // Make full-width on mobile
        >
          Send
        </Button>
      </Flex>

      {/* Predefined Questions */}
      <Wrap spacing="10px" justify="center" width={isMobile ? "100%" : "80%"}>
        {predefinedQuestions.map((question, index) => (
          <WrapItem key={index}>
            <Button
              size={isMobile ? "xs" : "sm"} // Reduce button size for mobile
              variant="outline"
              borderColor="gray.400"
              borderRadius="2xl"
              color="gray.500"
              onClick={() => handleSendMessage(question)}
              isDisabled={isLoading}
              _hover={{ bg: "xelerit.lightBlue", color: "white" }}
              p={isMobile ? 2 : 3} // Reduce padding on mobile
              whiteSpace="normal" // Allow text wrapping
              textAlign="center"
              maxWidth="100%" // Prevent overflow
            >
              {question}
            </Button>
          </WrapItem>
        ))}
      </Wrap>
    </Flex>
  );
};

export default InitialMessagePrompt;
