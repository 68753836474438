import React, { useState } from "react";
import {
  Box,
  VStack,
  Heading,
  Button,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FiHome,
  FiUsers,
  FiMessageCircle,
  FiBarChart2,
  FiFileText,
  FiSettings,
} from "react-icons/fi";
import DynamicDrawer from "./DynamicDrawer";

const AdminSidebar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activePanel, setActivePanel] = useState(null);

  const menuItems = [
    { name: "Overview", icon: FiHome, content: null },
    { name: "Users", icon: FiUsers, content: "UserManagement" },
    { name: "Chat Stats", icon: FiMessageCircle, content: "ChatStats" },
    { name: "Translation Stats", icon: FiBarChart2, content: "TranslationStats" },
    { name: "Feedbacks/Requests", icon: FiFileText, content: "FeedbackRequests" },
    { name: "Reports", icon: FiFileText, content: "Reports" },
    { name: "Settings", icon: FiSettings, content: "Settings" },
  ];

  const handleMenuClick = (content) => {
    setActivePanel(content); // Set the active panel dynamically
    onOpen(); // Open the Drawer
  };

  return (
    <>
      <Box
        w="300px"
        h="100vh"
        bg="vscode.sidebar"
        color="white"
        py={8}
        px={4}
        boxShadow="lg"
      >
        <Heading mb={4} textAlign="center">
          Admin Panel
        </Heading>
        <VStack align="start" spacing={4}>
          {menuItems.map((item) => (
            <Button
              key={item.name}
              variant="ghost"
              colorScheme="whiteAlpha"
              justifyContent="start"
              leftIcon={<Icon as={item.icon} boxSize={5} />}
              w="full"
              _hover={{ bg: "xelerit.lightBlue" }}
              onClick={() => handleMenuClick(item.content)}
            >
              {item.name}
            </Button>
          ))}
        </VStack>
      </Box>

      {/* Dynamic Drawer */}
      <DynamicDrawer isOpen={isOpen} onClose={onClose} activePanel={activePanel} />
    </>
  );
};

export default AdminSidebar;

