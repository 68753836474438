import { parser } from "./abbParser.js";
import {
  HighlightStyle,
  syntaxHighlighting,
  foldNodeProp,
  indentNodeProp,
  LRLanguage,
  LanguageSupport,
} from "@codemirror/language";
import { styleTags, tags as t } from "@lezer/highlight";
import { Movement } from "./abbParser.terms.js";

const colors = {
  vscode: {
    background: "#1e1e1e",        // Editor background
    foreground: "#d4d4d4",        // Editor foreground
    primaryBlue: "#569cd6",       // Primary blue
    secondaryYellow: "#dcdcaa",    // Secondary yellow
    errorRed: "#f44747",          // Error red
    successGreen: "#608b4e",      // Success green
    accentPurple: "#c586c0",      // Accent purple
    sidebar: "#252526",          // Sidebar background
    terminal: "#1e1e1e",         // Terminal background
  },
  xelerit: {
    background: "#19191a",
    grey: "#E9EEF2",             // Grey
    lightBlue: "#08BEF7",        // Light blue
    mediumBlue: "#0A81B4",       // Medium blue
    darkBlue: "#081F2D",         // Dark blue
    orange: "#FF8500",           // Orange
  }
};

// Map each syntax token to a color from your palette.
// (Note: t.keyword and t.moduleKeyword are both assigned xelerit.lightBlue,
//  as in your original snippet.)
export const abbHighlightStyle = HighlightStyle.define([
  { tag: t.keyword,           color: colors.xelerit.lightBlue },    // "#08BEF7"
  { tag: t.comment,           color: colors.vscode.successGreen },  // "#608b4e"
  { tag: t.string,            color: colors.vscode.primaryBlue },   // "#569cd6"
  { tag: t.function,          color: colors.vscode.orange },  // "#c586c0"
  { tag: t.number,            color: colors.vscode.accentPurple },       // "#FF8500"
  { tag: t.controlKeyword,    color: colors.xelerit.orange },   // "#0A81B4"
  { tag: t.moduleKeyword,     color: colors.xelerit.orange },    // "#08BEF7"
  { tag: t.variableName,      color: colors.vscode.secondaryYellow }, // "#dcdcaa"
  { tag: t.operator,          color: colors.vscode.errorRed },      // "#f44747"
  { tag: t.tagName,           color: colors.xelerit.darkBlue },     // "#081F2D"
  { tag: t.definitionKeyword, color: colors.xelerit.orange },        // "#E9EEF2"
  { tag: t.typeName, color: colors.xelerit.orange},
  { tag: t.angleBracket, color: '#5c6166' },


  

]);

// Configure parser with metadata.
// Make sure that the keys here match the node names generated by your grammar.
let parserWithMetadata = parser.configure({
  props: [
    styleTags({
      // If your grammar produces a node named "Keyword"
      Keyword: t.keyword,
      // If you now have a function call node (e.g., FCNCall) in your grammar:
      FCNCall: t.function,
      String: t.string,
      
      Comment: t.comment,
      Movement: t.string,

      Program: t.moduleKeyword,
      Comment: t.comment,
      String: t.string,
      FCNCall: t.function,
      Number: t.number,
      Control: t.controlKeyword,
      Movement: t.string,
      BuiltInVar: t.variableName,
      Operator: t.operator,
      VarType: t.typeName,
      StorageQualifier: t.definitionKeyword,
      
      // Add more mappings as necessary.
    }),
    foldNodeProp.add({
      // You can add folding rules here if needed.
    }),
    indentNodeProp.add({
      // You can add indentation rules here if needed.
    }),
  ],
});

// Define the language. Note: update comment tokens if your grammar uses a different one.
export const abbLanguage = LRLanguage.define({
  parser: parserWithMetadata,
  languageData: {
    // For example, if your grammar uses "!" for line comments:
    commentTokens: { line: "!" },
  },
});

// Export the language support
export function abb() {
  return new LanguageSupport(
    abbLanguage,
    [syntaxHighlighting(abbHighlightStyle)]
  );
}
