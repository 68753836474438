import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Spinner,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { getAllUsers, updateUser, deleteUser, createUser } from "../../api/users";

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newUser, setNewUser] = useState({
    username: "",
    email: "",
    password: "",
    role: "base",
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [actionType, setActionType] = useState(""); // "delete" or "update"
  const cancelRef = useRef();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userData = await getAllUsers();
        setUsers(userData);
      } catch (error) {
        console.error("Error fetching users:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const confirmAction = (type, user) => {
    setActionType(type);
    setSelectedUser(user);
    onAlertOpen();
  };

  const handleAction = async () => {
    if (actionType === "delete" && selectedUser) {
      try {
        await deleteUser(selectedUser.id);
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== selectedUser.id));
        toast({ title: "User deleted successfully.", status: "success" });
      } catch (error) {
        console.error("Error deleting user:", error.message);
        toast({ title: "Error deleting user.", status: "error" });
      }
    } else if (actionType === "update" && selectedUser) {
      try {
        await updateUser(selectedUser.id, { role: "premium" });
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === selectedUser.id ? { ...user, role: "premium" } : user
          )
        );
        toast({ title: "Role updated successfully.", status: "success" });
      } catch (error) {
        console.error("Error updating user role:", error.message);
        toast({ title: "Error updating role.", status: "error" });
      }
    }
    onAlertClose();
  };

  const handleCreateUser = async () => {
    try {
      const newUserData = await createUser(newUser);
      setUsers((prevUsers) => [...prevUsers, newUserData]);
      toast({ title: "User created successfully.", status: "success" });
      onClose();
    } catch (error) {
      console.error("Error creating user:", error.message);
      toast({ title: "Error creating user.", status: "error" });
    }
  };

  if (loading) {
    return (
      <Box p={8}>
        <Spinner size="xl" />
        <Heading size="md">Loading users...</Heading>
      </Box>
    );
  }

  return (
    <Box p={8} borderWidth={1} borderRadius="lg" backgroundColor={"vscode.background"}>
      <Heading mb={4}>User Management</Heading>
      <Button colorScheme="green" mb={4} onClick={onOpen}>
        Add New User
      </Button>
      <Table variant="simple">
        <thead>
          <Tr>
            <Th>Username</Th>
            <Th>Email</Th>
            <Th>Role</Th>
            <Th>Actions</Th>
          </Tr>
        </thead>
        <Tbody>
          {users.map((user) => (
            <Tr key={user.id}>
              <Td>{user.username}</Td>
              <Td>{user.email}</Td>
              <Td>{user.role}</Td>
              <Td>
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={() => confirmAction("update", user)}
                >
                  Set to Premium
                </Button>
                <Button
                  size="sm"
                  colorScheme="red"
                  ml={2}
                  onClick={() => confirmAction("delete", user)}
                >
                  Delete
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Modals and Alerts */}
      {/* Add User Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent backgroundColor={"vscode.background"}>
          <ModalHeader>Add New User</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Username</FormLabel>
              <Input
                value={newUser.username}
                onChange={(e) =>
                  setNewUser((prev) => ({ ...prev, username: e.target.value }))
                }
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Input
                value={newUser.email}
                onChange={(e) =>
                  setNewUser((prev) => ({ ...prev, email: e.target.value }))
                }
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={newUser.password}
                onChange={(e) =>
                  setNewUser((prev) => ({ ...prev, password: e.target.value }))
                }
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Role</FormLabel>
              <Input
                value={newUser.role}
                onChange={(e) =>
                  setNewUser((prev) => ({ ...prev, role: e.target.value }))
                }
                placeholder="base, premium, admin"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCreateUser}>
              Create User
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Confirmation Dialog */}
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Action
            </AlertDialogHeader>
            <AlertDialogBody>
              {actionType === "delete"
                ? `Are you sure you want to delete user "${selectedUser?.username}"?`
                : `Are you sure you want to update the role of user "${selectedUser?.username}" to Premium?`}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onAlertClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleAction} ml={3}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default UserManagement;
