import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  useDisclosure
} from "@chakra-ui/react";
import { getAllProjects, createProject, getProjectDetails } from "../api/projects";
import { useAuth } from "../hooks/AuthProvider"; // Import Auth Hook
import UploadRequirements from "./UploadRequirements"; // Import the UploadRequirements component
import Upload3DModel from "./Upload3DModel"; // Import the Upload3DModel component

const ProjectPopup = ({ isOpen, onClose, onSelectProject, onProjectCreated }) => {
  const { user } = useAuth(); // Logged-in user
  const [projects, setProjects] = useState([]);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [newProject, setNewProject] = useState({
    name: "",
    description: "",
    plc_brand: "",
    robot_brand: "",
    user_id: user?.id || "", // Default to logged-in user
  });

  const { isOpen: isUploadOpen, onOpen: onUploadOpen, onClose: onUploadClose } = useDisclosure();
  const { isOpen: isUpload3DOpen, onOpen: onUpload3DOpen, onClose: onUpload3DClose } = useDisclosure();

  useEffect(() => {
    if (isOpen) {
      fetchProjects();
    }
  }, [isOpen]);

  // Fetch projects for the logged-in user
  const fetchProjects = async () => {
    try {
      const response = await getAllProjects();
      const userProjects = response; // Filter by user
      setProjects(userProjects);
    } catch (error) {
      console.error("Failed to fetch projects:", error);
      setProjects([]); // Set to empty array in case of error
    }
  };

  const fetchProjectDetails = async (projectId) => {
    try {
      // Fetch project details including flow diagram and nodes
      const response = await getProjectDetails(projectId);
      const { plc_brand, robot_brand, flow_diagram } = response;
    } catch (error) {
      console.error("Error fetching project details:", error);
    }
  };

  // Handle creating a new project
  const handleCreate = async () => {
    try {
      const createdProject = await createProject(newProject);
      fetchProjects(); // Refresh project list
      setIsCreateMode(false); // Close create mode
      setNewProject({ name: "", description: "", plc_brand: "", robot_brand: "", user_id: user?.id }); // Reset form
      onSelectProject(createdProject.id); // Open the new project
      onProjectCreated(); // Call the callback to show the upload requirements component
      onUploadOpen(); // Open the upload requirements modal
    } catch (error) {
      console.error("Failed to create project:", error);
    }
  };

  // Handle selecting an existing project
  const handleSelectProject = (projectId) => {
    onSelectProject(projectId); // Pass selected project ID to parent component
    fetchProjectDetails(projectId);
    onClose(); // Close the popup
  };

  const handleUploadRequirements = (uploadedData) => {
    // Implement the upload logic here
    console.log("Uploaded requirements data:", uploadedData);
    onUploadClose(); // Hide the upload requirements component after upload
    onUpload3DOpen(); // Open the upload 3D model modal
  };

  const handleUpload3DModel = (uploadedData) => {
    // Implement the upload logic here
    console.log("Uploaded 3D model data:", uploadedData);
    onUpload3DClose(); // Hide the upload 3D model component after upload
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
        <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(5px)" />
        <ModalContent borderRadius="md" bg="vscode.sidebar" p={4}>
          <ModalHeader fontSize="2xl" fontWeight="bold" textAlign="center">
            {isCreateMode ? "Create New Project" : "Your Projects"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="center" justify="center" height="100%">
              {isCreateMode ? (
                <>
                  <FormControl mb={4}>
                    <FormLabel>Project Name</FormLabel>
                    <Input
                      value={newProject.name}
                      onChange={(e) =>
                        setNewProject((prev) => ({ ...prev, name: e.target.value }))
                      }
                    />
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel>Description</FormLabel>
                    <Input
                      value={newProject.description}
                      onChange={(e) =>
                        setNewProject((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                    />
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel>PLC Manufacturer</FormLabel>
                    <Select
                      placeholder="Select PLC Manufacturer"
                      value={newProject.plc_brand}
                      onChange={(e) =>
                        setNewProject((prev) => ({
                          ...prev,
                          plc_brand: e.target.value,
                        }))
                      }
                    >
                      <option value="siemens">Siemens</option>
                    </Select>
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel>Robot Brand</FormLabel>
                    <Select
                      placeholder="Select Robot Brand"
                      value={newProject.robot_brand}
                      onChange={(e) =>
                        setNewProject((prev) => ({
                          ...prev,
                          robot_brand: e.target.value,
                        }))
                      }
                    >
                      <option value="estun">Estun</option>
                      <option value="abb">ABB</option>
                      <option value="kuka">KUKA</option>
                    </Select>
                  </FormControl>
                </>
              ) : (
                <>
                  <Button
                    mb={4}
                    backgroundColor="xelerit.orange"
                    color={"white"}
                    onClick={() => setIsCreateMode(true)}
                  >
                    Add New Project
                  </Button>
                  <Text fontSize="lg" textAlign="center" mb={4}>
                    or
                  </Text>
                  {projects.length > 0 ? (
                    <VStack>
                      {projects.map((project) => (
                        <Box
                          key={project.id}
                          p={4}
                          borderRadius="md"
                          bg="vscode.foreground"
                          _hover={{ bg: "xelerit.orange", cursor: "pointer" }}
                          onClick={() => handleSelectProject(project.id)} // Select project
                          width={"100%"}
                        >
                          <Text fontSize="md" color="vscode.sidebar">
                            {project.name}
                          </Text>
                          <Text fontSize="xs" color="gray.600">
                            {project.description || "No description provided"}
                          </Text>
                          <Text fontSize="xs" color="gray.500">
                            Robot Brand: {project.robot_brand || "N/A"}
                          </Text>
                        </Box>
                      ))}
                    </VStack>
                  ) : (
                    <Text fontSize="lg" textAlign="center" color="gray.600">
                      No projects available.
                    </Text>
                  )}
                </>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="center">
            {isCreateMode ? (
              <>
                <Button
                  backgroundColor="xelerit.orange"
                  color="white"
                  mr={3}
                  onClick={handleCreate}
                  isDisabled={!newProject.name || !newProject.description || !newProject.plc_brand || !newProject.robot_brand} // Validate required fields
                >
                  Create and Open
                </Button>
                <Button onClick={() => setIsCreateMode(false)}>Cancel</Button>
              </>
            ) : (
              <Button colorScheme="gray" onClick={onClose}>
                Close
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Upload Requirements Modal */}
      <UploadRequirements
        isOpen={isUploadOpen}
        onClose={onUploadClose}
        onUpload={handleUploadRequirements}
      />

      {/* Upload 3D Model Modal */}
      <Upload3DModel
        isOpen={isUpload3DOpen}
        onClose={onUpload3DClose}
        onUpload={handleUpload3DModel}
      />
    </>
  );
};

export default ProjectPopup;