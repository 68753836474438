import React from "react";
import { useTheme } from "@chakra-ui/react";
import CookieConsent from "react-cookie-consent";

const CookieBanner = () => {
  const theme = useTheme(); // Access theme colors

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Reject"
      enableDeclineButton
      cookieName="xeleritCookieConsent"
      style={{ background: theme.colors.xelerit.background, textAlign: "center", height: "60px" }} // Xelerit background color
      buttonStyle={{
        color: theme.colors.vscode.background, // VSCode foreground color
        background: theme.colors.vscode.foreground, // Light Blue for accept button
        fontSize: "14px",
        borderRadius: "5px",
      }}
      declineButtonStyle={{
        color: theme.colors.vscode.background, // VSCode foreground color
        background: theme.colors.vscode.foreground, // Light Blue for accept button
        fontSize: "14px",
        borderRadius: "5px",
      }}
      onAccept={() => {
        console.log("Cookies accepted");
      }}
      onDecline={() => {
        console.log("Cookies declined");
      }}
      expires={365}
    >
      This website uses cookies to enhance the user experience.{" "}
      <a
        href="/privacy"
        style={{
          color: theme.colors.xelerit.lightBlue, // Light Blue for link
          textDecoration: "underline",
        }}
      >
        Learn More
      </a>
    </CookieConsent>
  );
};

export default CookieBanner;

