import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  HStack,
  Input,
  Select,
  Button,
  IconButton,
  Divider,
  Textarea,
} from "@chakra-ui/react";
import { useTheme } from "@chakra-ui/react";
import { FiPlus, FiTrash2, FiEdit, FiSave } from "react-icons/fi";

const VARIABLE_TYPES = ["BOOL", "INT", "REAL", "STRING", "ARRAY", "OBJECT", "FUNCTION"];
const CRITICALITY_TYPES = ["LOW", "MEDIUM", "HIGH"];

const GroupNodeDetails = ({ groupNode, childNodes, onUpdate, onClose }) => {
  const theme = useTheme();

  // Local state for group node data and child nodes
  const [localGroupLabel, setLocalGroupLabel] = useState(groupNode.data.label || "Group Node");
  const [localInputNodes, setLocalInputNodes] = useState(childNodes.filter((child) => child.type === "inputNode"));
  const [localOutputNodes, setLocalOutputNodes] = useState(childNodes.filter((child) => child.type === "outputNode"));
  const [localTaskNode, setLocalTaskNode] = useState(childNodes.find((child) => child.type === "taskNode") || null);
  const [localErrorHandlingNode, setLocalErrorHandlingNode] = useState(childNodes.find((child) => child.type === "errorHandlingNode") || null);
  const [localRecoveryNode, setLocalRecoveryNode] = useState(childNodes.find((child) => child.type === "recoveryNode") || null);
  const [isEditMode, setIsEditMode] = useState(false);

  // When not editing, keep local state in sync with incoming props
  useEffect(() => {
    if (!isEditMode) {
      setLocalGroupLabel(groupNode.data.label || "Group Node");
      setLocalInputNodes(childNodes.filter((child) => child.type === "inputNode"));
      setLocalOutputNodes(childNodes.filter((child) => child.type === "outputNode"));
      setLocalTaskNode(childNodes.find((child) => child.type === "taskNode") || null);
      setLocalErrorHandlingNode(childNodes.find((child) => child.type === "errorHandlingNode") || null);
      setLocalRecoveryNode(childNodes.find((child) => child.type === "recoveryNode") || null);
    }
  }, [childNodes, groupNode, isEditMode]);

  // Update functions for input and output nodes
  const handleUpdateNodeVars = (nodeType, nodeId, updatedVars) => {
    if (nodeType === "inputNode") {
      const newInputs = localInputNodes.map((node) =>
        node.id === nodeId ? { ...node, data: { ...node.data, vars: updatedVars } } : node
      );
      setLocalInputNodes(newInputs);
    } else if (nodeType === "outputNode") {
      const newOutputs = localOutputNodes.map((node) =>
        node.id === nodeId ? { ...node, data: { ...node.data, vars: updatedVars } } : node
      );
      setLocalOutputNodes(newOutputs);
    }
  };

  // Update function for the task node
  const handleTaskNodeUpdate = (key, value) => {
    if (!localTaskNode) return;
    const updated = { ...localTaskNode, data: { ...localTaskNode.data, [key]: value } };
    setLocalTaskNode(updated);
  };

  // Update functions for error handling and recovery nodes
  const handleSubNodeUpdate = (nodeType, key, value) => {
    if (nodeType === "errorHandlingNode" && localErrorHandlingNode) {
      const updated = { ...localErrorHandlingNode, data: { ...localErrorHandlingNode.data, [key]: value } };
      setLocalErrorHandlingNode(updated);
    } else if (nodeType === "recoveryNode" && localRecoveryNode) {
      const updated = { ...localRecoveryNode, data: { ...localRecoveryNode.data, [key]: value } };
      setLocalRecoveryNode(updated);
    }
  };

  // Render helper for variables
  const renderVariable = (variables, updateVars) => {
    return variables.map((variable, index) => (
      <HStack key={index} spacing={2} align="start">
        {isEditMode ? (
          <>
            <Select
              placeholder="Type"
              value={variable.type}
              onChange={(e) => {
                const updated = [...variables];
                updated[index].type = e.target.value;
                updateVars(updated);
              }}
              size="sm"
            >
              {VARIABLE_TYPES.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Select>
            <Input
              placeholder="Name"
              value={variable.name}
              onChange={(e) => {
                const updated = [...variables];
                updated[index].name = e.target.value;
                updateVars(updated);
              }}
              size="sm"
            />
            <Input
              placeholder="Default Value"
              value={variable.defaultValue}
              onChange={(e) => {
                const updated = [...variables];
                updated[index].defaultValue = e.target.value;
                updateVars(updated);
              }}
              size="sm"
            />
            <IconButton
              icon={<FiTrash2 />}
              size="xs"
              colorScheme="red"
              onClick={() => {
                const updated = variables.filter((_, i) => i !== index);
                updateVars(updated);
              }}
            />
          </>
        ) : (
          <>
            <Text>{variable.type}</Text>
            <Text>{variable.name}</Text>
            <Text>{variable.defaultValue}</Text>
          </>
        )}
      </HStack>
    ));
  };

  const renderAlarms = (alarms, updateAlarms) =>
    alarms.map((alarm, index) => (
      <HStack key={index} spacing={2} align="start">
        {isEditMode ? (
          <>
            <Input
              placeholder="Alarm Name"
              value={alarm.name}
              onChange={(e) => {
                const updated = [...alarms];
                updated[index].name = e.target.value;
                updateAlarms(updated);
              }}
              size="sm"
            />
            <Input
              placeholder="Message"
              value={alarm.message}
              onChange={(e) => {
                const updated = [...alarms];
                updated[index].message = e.target.value;
                updateAlarms(updated);
              }}
              size="sm"
            />
            <Select
              placeholder="Criticality"
              value={alarm.criticality}
              onChange={(e) => {
                const updated = [...alarms];
                updated[index].criticality = e.target.value;
                updateAlarms(updated);
              }}
              size="sm"
            >
              {CRITICALITY_TYPES.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Select>
            <IconButton
              icon={<FiTrash2 />}
              size="xs"
              colorScheme="red"
              onClick={() => {
                const updated = alarms.filter((_, i) => i !== index);
                updateAlarms(updated);
              }}
            />
          </>
        ) : (
          <>
            <Text>{alarm.name}</Text>
            <Text>{alarm.message}</Text>
            <Text>{alarm.criticality}</Text>
          </>
        )}
      </HStack>
    ));

  const renderRecoveryActions = (actions, updateActions) =>
    actions.map((action, index) => (
      <HStack key={index} spacing={2} align="start">
        {isEditMode ? (
          <>
            <Input
              placeholder="Action"
              value={action}
              onChange={(e) => {
                const updated = [...actions];
                updated[index] = e.target.value;
                updateActions(updated);
              }}
              size="sm"
            />
            <IconButton
              icon={<FiTrash2 />}
              size="xs"
              colorScheme="red"
              onClick={() => {
                const updated = actions.filter((_, i) => i !== index);
                updateActions(updated);
              }}
            />
          </>
        ) : (
          <Text>{action}</Text>
        )}
      </HStack>
    ));

  // When the user clicks Save, call onUpdate with all updated data
  const handleSave = () => {
    const updatedGroupNode = {
      ...groupNode,
      data: { ...groupNode.data, label: localGroupLabel },
    };

    // Combine updated child nodes into one array
    let updatedChildNodes = [
      ...localInputNodes,
      ...localOutputNodes,
      ...(localTaskNode ? [localTaskNode] : []),
      ...(localErrorHandlingNode ? [localErrorHandlingNode] : []),
      ...(localRecoveryNode ? [localRecoveryNode] : []),
    ];

    // Call onUpdate only once with all the updates
    onUpdate(updatedGroupNode, updatedChildNodes);
    
    setIsEditMode(false);
    onClose();
  };

  return (
    <Box
      p={4}
      bg="vscode.background"
      color="white"
      right="0"
      top="0"
      height="100vh"
      overflowY="auto"
      boxShadow="lg"
    >
      <HStack justify="flex-end" mb={4}>
        <IconButton
          icon={isEditMode ? <FiSave /> : <FiEdit />}
          onClick={() => {
            if (isEditMode) {
              handleSave();
            } else {
              setIsEditMode(true);
            }
          }}
          backgroundColor={isEditMode ? "green.500" : "xelerit.lightBlue"}
          color="white"
        />
      </HStack>

      {/* Group Label */}
      <HStack align="center" mb={4}>
        <Text fontWeight="bold">Group Label:</Text>
        {isEditMode ? (
          <Input
            value={localGroupLabel}
            onChange={(e) => setLocalGroupLabel(e.target.value)}
            size="sm"
          />
        ) : (
          <Text>{localGroupLabel}</Text>
        )}
      </HStack>

      {/* Task Node */}
      {localTaskNode && (
        <>
          <HStack align="center" mb={4}>
            <Text fontWeight="bold">Filename:</Text>
            {isEditMode ? (
              <Input
                value={localTaskNode.data.fileName || ""}
                onChange={(e) =>
                  handleTaskNodeUpdate("fileName", e.target.value)
                }
                size="sm"
              />
            ) : (
              <Text>{localTaskNode.data.fileName}</Text>
            )}
          </HStack>
          <HStack align="start" mb={4}>
            <Text fontWeight="bold">Description:</Text>
            {isEditMode ? (
              <Textarea
                value={localTaskNode.data.description || ""}
                onChange={(e) =>
                  handleTaskNodeUpdate("description", e.target.value)
                }
                size="sm"
                resize="vertical"
                minHeight="80px"
              />
            ) : (
              <Text>{localTaskNode.data.description}</Text>
            )}
          </HStack>
        </>
      )}

      <Divider my={4} />

      {/* Input Nodes */}
      <Text fontWeight="bold" fontSize="md" mb={4}>
        Inputs
      </Text>
      {localInputNodes.map((node) => (
        <Box key={node.id} bg="vscode.sidebar" p={4} borderRadius="md" mb={4}>
          <VStack spacing={2} align="stretch">
            {renderVariable(node.data.vars || [], (updatedVars) =>
              handleUpdateNodeVars("inputNode", node.id, updatedVars)
            )}
            {isEditMode && (
              <Button
                size="xs"
                onClick={() =>
                  handleUpdateNodeVars("inputNode", node.id, [
                    ...(node.data.vars || []),
                    { type: "", name: "", defaultValue: "" },
                  ])
                }
              >
                <FiPlus /> Add Variable
              </Button>
            )}
          </VStack>
        </Box>
      ))}

      <Divider my={4} />

      {/* Output Nodes */}
      <Text fontWeight="bold" fontSize="md" mb={4}>
        Outputs
      </Text>
      {localOutputNodes.map((node) => (
        <Box key={node.id} bg="vscode.sidebar" p={4} borderRadius="md" mb={4}>
          <VStack spacing={2} align="stretch">
            {renderVariable(node.data.vars || [], (updatedVars) =>
              handleUpdateNodeVars("outputNode", node.id, updatedVars)
            )}
            {isEditMode && (
              <Button
                size="xs"
                onClick={() =>
                  handleUpdateNodeVars("outputNode", node.id, [
                    ...(node.data.vars || []),
                    { type: "", name: "", defaultValue: "" },
                  ])
                }
              >
                <FiPlus /> Add Variable
              </Button>
            )}
          </VStack>
        </Box>
      ))}

      <Divider my={4} />

      {/* Error Handling Node */}
      {localErrorHandlingNode && (
        <>
          <Text fontWeight="bold" fontSize="md" mb={4}>
            Alarms
          </Text>
          <Box bg="vscode.sidebar" p={4} borderRadius="md" mb={4}>
            <VStack spacing={2} align="stretch">
              {renderAlarms(
                localErrorHandlingNode.data.alarms || [],
                (updatedAlarms) =>
                  handleSubNodeUpdate("errorHandlingNode", "alarms", updatedAlarms)
              )}
              {isEditMode && (
                <Button
                  size="xs"
                  onClick={() =>
                    handleSubNodeUpdate("errorHandlingNode", "alarms", [
                      ...(localErrorHandlingNode.data.alarms || []),
                      { name: "", message: "", criticality: "LOW" },
                    ])
                  }
                >
                  <FiPlus /> Add Alarm
                </Button>
              )}
            </VStack>
          </Box>
        </>
      )}

      <Divider my={4} />

      {/* Recovery Node */}
      {localRecoveryNode && (
        <>
          <Text fontWeight="bold" fontSize="md" mb={4}>
            Recovery Actions
          </Text>
          <Box bg="vscode.sidebar" p={4} borderRadius="md" mb={4}>
            <VStack spacing={2} align="stretch">
              {renderRecoveryActions(
                localRecoveryNode.data.actions || [],
                (updatedActions) =>
                  handleSubNodeUpdate("recoveryNode", "actions", updatedActions)
              )}
              {isEditMode && (
                <Button
                  size="xs"
                  onClick={() =>
                    handleSubNodeUpdate("recoveryNode", "actions", [
                      ...(localRecoveryNode.data.actions || []),
                      "",
                    ])
                  }
                >
                  <FiPlus /> Add Action
                </Button>
              )}
            </VStack>
          </Box>
        </>
      )}

      <Divider my={4} />

      {/* Close Button */}
      <Button
        backgroundColor={isEditMode ? "xelerit.orange" : "vscode.foreground"}
        color={isEditMode ? "white" : "black"}
        size="sm"
        onClick={onClose}
        w="full"
      >
        Close
      </Button>
    </Box>
  );
};

export default GroupNodeDetails;
