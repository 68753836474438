import React, { useState } from "react";
import {
  Box, Button, VStack, Text, Divider, Input, HStack, useDisclosure, IconButton
} from "@chakra-ui/react";
import DependencyGraph from "./DependencyGraph";
import GraphVisualization from "../../pages/premium/testGraph";
import HelpGraphModal from "../modals/HelpGraphModal";
import { QuestionIcon } from "@chakra-ui/icons";

const DocPreview = ({ previewData, setPreviewData }) => {
  const [isEditing, setIsEditing] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleEditChange = (field, value) => {
    setPreviewData(prev => ({ ...prev, [field]: value }));
  };

  const handleMetaChange = (field, value) => {
    setPreviewData(prev => ({
      ...prev,
      meta: { ...prev.meta, [field]: value }
    }));
  };

  return (
    <Box p={5} border="1px" borderRadius="md" w="100%">
      <HStack align={"flex-end"}>
        <Button onClick={() => setIsEditing(!isEditing)}>
          {isEditing ? "Save" : "Edit"}
        </Button>
      </HStack>
      {previewData && (
        <VStack align="start" spacing={3} mt={3}>
          <Text><strong>Title of Project:</strong></Text>
          {isEditing ? (
            <Input
              value={previewData.title || ""}
              onChange={(e) => handleEditChange("title", e.target.value)}
            />
          ) : (
            <Text>{previewData.title || "N/A"}</Text>
          )}
          <Text><strong>Robot Brand:</strong></Text>
          {isEditing ? (
            <Input
              value={previewData.meta?.robot_brand || ""}
              onChange={(e) => handleMetaChange("robot_brand", e.target.value)}
            />
          ) : (
            <Text>{previewData.meta?.robot_brand}</Text>
          )}
          <Text><strong>PLC Manufacturer:</strong></Text>
          {isEditing ? (
            <Input
              value={previewData.meta?.plc_manufacturer || ""}
              onChange={(e) => handleMetaChange("plc_manufacturer", e.target.value)}
            />
          ) : (
            <Text>{previewData.meta?.plc_manufacturer}</Text>
          )}
          <Text><strong>Description of the Project:</strong></Text>
          {isEditing ? (
            <Input
              value={previewData.description || ""}
              onChange={(e) => handleMetaChange("description", e.target.value)}
            />
          ) : (
            <Text>{previewData.description}</Text>
          )}
          <Text><strong>Client:</strong></Text>
          {isEditing ? (
            <Input
              value={previewData.meta?.client || ""}
              onChange={(e) => handleMetaChange("client", e.target.value)}
            />
          ) : (
            <Text>{previewData.meta?.client}</Text>
          )}
          <Text><strong>Timestamp:</strong></Text>
          <Text>{new Date().toLocaleString()}</Text>
          <Divider />

          <Text fontSize="lg"><strong>Project Folder Structure:</strong></Text>
          <Box p={2} bg="vscode.sidebar" w="100%" borderRadius="md">
            <pre>{previewData.project_folder}</pre>
          </Box>

          <Divider />
          <HStack>
            <Text fontSize="lg"><strong>Processes:</strong></Text>
            <IconButton
              onClick={onOpen}
              bg="white"
              size="sm"
              icon={<QuestionIcon />}
              aria-label="Help"
            />
          </HStack>
          <GraphVisualization jsonData = {previewData} onCapture={(dataUrl) =>
    setPreviewData(prev => ({ ...prev, code_image: dataUrl }))
  }  />
        </VStack>
      )}
      <HelpGraphModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default DocPreview;
