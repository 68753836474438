import React, { memo } from "react";
import { Handle } from "reactflow";
import { Box, Text, VStack, HStack } from "@chakra-ui/react";
import { FaArrowCircleRight } from "react-icons/fa";


const TaskNode = ({ data, isConnectable }) => {
  const inputs = data.inputs || [];
  const outputs = data.outputs || [];

  return (
    <div
      style={{
        background: "#1e1e1e",
        color: "white",
        padding: "10px",
        borderRadius: "5px",
        width: "200px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        position: "relative",
      }}
    >
      {/* Handle for input */}
      <Handle
        type="target"
        position="top"
        id="input"
        style={{ width: '12px', height: '12px' }}
        isConnectable={isConnectable}
      />

      {/* Name and Description */}
      <Box  p={2} borderRadius="md" width="100%" mb={2}>
        <Text fontWeight="bold" fontSize="sm">
          {data.fileName || "Untitled"}
        </Text>
        <Text fontSize="xs" color="gray.400">
          {data.description || "No description"}
        </Text>
      </Box>


      {/* Handle for output */}
      <Handle
        type="source"
        position="bottom"
        id="output"
        style={{ width: '12px', height: '12px' }}
        isConnectable={isConnectable}
      />
    </div>
  );
};

export default memo(TaskNode);