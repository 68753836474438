import { useState, useEffect } from "react";
import { getChatLogs } from "../api/message_chat_bot";

const useChatLogs = () => {
  const [chatLogs, setChatLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChatLogs = async () => {
      try {
        const response = await getChatLogs();
        setChatLogs(response);
      } catch (error) {
        console.error("Error fetching chat logs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchChatLogs();
  }, []);

  return { chatLogs, loading };
};

export default useChatLogs;
