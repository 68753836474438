import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./hooks/AuthProvider";

const ProtectedRoute = ({ children, roles = [] }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // If user is not authenticated, redirect to login
    if (!user) {
      navigate("/login", { replace: true });
    }
    // If user's role does not match the allowed roles, redirect to unauthorized
    else if (roles.length > 0 && !roles.includes(user.role)) {
      navigate("/unauthorized", { replace: true });
    }
  }, [user, roles, navigate]);

  // Only render children if the user is authenticated and authorized
  if (!user || (roles.length > 0 && !roles.includes(user.role))) {
    return null; // Prevent rendering before redirection
  }

  return children;
};

export default ProtectedRoute;

