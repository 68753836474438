import React, { useState } from "react";
import {
  Box,
  VStack,
  Divider,
  IconButton,
  Tooltip,
  Text,
  HStack,
  Button,
} from "@chakra-ui/react";
import {
  FaComments,
  FaProjectDiagram,
  FaFile,
  FaChevronLeft,
  FaChevronRight,
  FaHelicopter,
  FaQuestionCircle,
} from "react-icons/fa";
import { useTheme } from "@chakra-ui/react";

const SidebarBase = ({ onPanelSelect }) => {
  const theme = useTheme();
  // State to track if the sidebar is open (expanded) or closed (collapsed)
  const [isOpen, setIsOpen] = useState(true);

  // Define the panels for the top section
  const topPanels = [
    {
      label: "Chat",
      icon: FaComments,
      id: "chat",
      hoverColor: theme.colors.vscode.primaryBlue,
    },
    {
      label: "Code generator",
      icon: FaProjectDiagram,
      id: "flowdiagram",
      hoverColor: theme.colors.vscode.primaryBlue,
    },
    {
      label: "Files",
      icon: FaFile,
      id: "files",
      hoverColor: theme.colors.vscode.primaryBlue,
    },
    {
      label: "Help",
      icon: FaQuestionCircle,
      id: "help",
      hoverColor: theme.colors.vscode.primaryBlue,
    },
  ];

  // Reusable function to render panel items.
  // When the sidebar is collapsed, we only show the icon.
  const renderPanelItems = (panels) =>
    panels.map((panel) => (
      <Tooltip
        key={panel.id}
        label={panel.label}
        placement="right"
        bg="vscode.background"
      >
        <HStack
          justifyContent={isOpen ? "flex-start" : "center"}
          alignItems="center"
          width="100%"
          paddingX={isOpen ? 4 : 0}
        >
          <Box
            as="button"
            aria-label={panel.id}
            variant="ghost"
            size="lg"
            color={theme.colors.vscode.foreground}
            _hover={{ bg: "vscode.background" }}
            onClick={() => onPanelSelect(panel.id)}
            display="flex"
            alignItems="center"
            padding={2}
            borderRadius="lg"
            width={isOpen ? "200px" : "auto"}
          >
            <panel.icon />
            {isOpen && <Text marginLeft={3}>{panel.label}</Text>}
          </Box>
        </HStack>
      </Tooltip>
    ));

  const handleButtonClick = (path) => {
    window.location.href = path;
  };

  return (
    <Box
      as="nav"
      bg={theme.colors.vscode.sidebar}
      // Change the width based on open/closed state
      width={isOpen ? "250px" : "60px"}
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      paddingY={4}
    >
      {/* Top Section */}
      <VStack spacing={4}>{renderPanelItems(topPanels)}</VStack>

      {/* Bottom Section */}
      <VStack spacing={4} marginBottom={4}>
        {isOpen && (
          <>
            <Button
              bg="xelerit.orange"
              color="white"
              width="200px"
              _hover={{ bg: "vscode.sidebar" }}
              onClick={() => handleButtonClick("/signup")}
            >
              Signup
            </Button>
            <Button
              bg="vscode.foreground"
              color="black"
              width="200px"
              _hover={{ bg: "vscode.sidebar", color: "white" }}
              onClick={() => handleButtonClick("/login")}
            >
              Login
            </Button>
          </>
        )}
        {/* Toggle Button to Open/Close the Sidebar */}
        <IconButton
          icon={isOpen ? <FaChevronLeft /> : <FaChevronRight />}
          onClick={() => setIsOpen(!isOpen)}
          aria-label="Toggle Sidebar"
          variant="ghost"
          color={theme.colors.vscode.foreground}
          _hover={{ bg: "vscode.background" }}
        />
      </VStack>
    </Box>
  );
};

export default SidebarBase;
