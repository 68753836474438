import React from "react";
import {
  Box,
  Flex,
  Spinner,
  Text,
  List,
  ListItem,
  Heading,
  OrderedList,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  background,
  useMediaQuery 
} from "@chakra-ui/react";

import { FaRobot } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { monokai, github, monokaiSublime, atomOneLight, grayscale, xcode, monoBlue} from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useTheme } from "@chakra-ui/react";

const Message = ({ msg, isLoading, isLast }) => {
  const isUser = msg.sender === "user";
  const theme = useTheme();

  // Parse <var> tags and return processed content
  const parseVars = (content) => {
    return content.replace(/<var>(.*?)<\/var>/g, (_, match) => {
      return `\`${match}\``; // Replace <var> with inline code syntax
    });
  };

  // Parse the sketchpad content if available
  const parseSketchpad = (content) => {
    const sketchpadMatch = content.match(/<sketchpad>(.*?)<\/sketchpad>/s);
    return sketchpadMatch ? sketchpadMatch[1].trim() : null;
  };

  // Parse the sources from the message content if available
  const parseSources = (content) => {
    try {
      const sourcesMatch = content.match(/<sources>(.*?)<\/sources>/);
      if (sourcesMatch) {
        const rawSources = sourcesMatch[1].trim();
        return JSON.parse(rawSources.replace(/'/g, '"')); // Replace single quotes for JSON compatibility
      }
    } catch (error) {
      console.error("Error parsing sources:", error);
    }
    return null; // Return null if parsing fails
  };
  
  const processedContent = parseVars(msg.content);
  const sketchpadContent = parseSketchpad(processedContent);
  const sources = parseSources(processedContent);
  const [isMobile] = useMediaQuery("(max-width: 768px)"); // Detect mobile


  return (
    <Flex justify={isUser ? "flex-end" : "flex-start"} py={2}>
      {!isUser && !isMobile && (
        <Box mr={3}>
          {isLoading && isLast ? (
            <Spinner size="md" color="xelerit.lightBlue" />
          ) : (
            <FaRobot size="24px" />
          )}
        </Box>
      )}
      <Box
        maxW={isMobile ? "90%" : "80%"} // Adjust width for mobile
        bg={isUser ? "xelerit.lightBlue" : ""}
        color={isUser ? "white" : "gray.100"}
        px={isMobile ? 3 : 5} // Reduce padding for mobile
        py={isMobile ? 2 : 4}
        borderRadius="xl"
      >
        <Text fontSize="xs" fontWeight="bold" mb={1}>
          {isUser ? "You" : "Bot"}
        </Text>

        {/* Main message content */}
        <ReactMarkdown
          components={{
            p: ({ children }) => (
              <Text
                fontSize="md"
                lineHeight="1.5"
                fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
                mb={0}
              >
                {children}
              </Text>
            ),
            strong: ({ children }) => (
              <Text
                as="strong"
                fontWeight="bold"
                fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
              >
                {children}
              </Text>
            ),
            em: ({ children }) => (
              <Text
                as="em"
                fontStyle="italic"
                fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
                my={4}
              >
                {children}
              </Text>
            ),
            code: ({ children }) => {
              const isInline = typeof children === "string" && !children.includes("\n");
                return isInline ? (
                <span
                  style={{
                  backgroundColor: theme.colors.vscode.sidebar,
                  color: theme.colors.xelerit.mediumBlue,
                  fontFamily: "monospace",
                  padding: "2px 4px",
                  borderRadius: "4px",
                  }}
                >
                  {children}
                </span>
                ) : (
                <SyntaxHighlighter
                  customStyle={{ borderRadius: "4px", backgroundColor: theme.colors.vscode.sidebar, color: "white"}}
                  style={atomOneDark}
                >
                  {String(children).trim()}
                </SyntaxHighlighter>
                );
            },
            ul: ({ children }) => (
              <List
                styleType="disc"
                pl={5}
                fontSize="md"
                lineHeight="1.5"
                fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
                my={4}
              >
                {children}
              </List>
            ),
            ol: ({ children }) => (
              <OrderedList
                styleType="decimal"
                pl={5}
                fontSize="md"
                lineHeight="1.5"
                fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
                my={4}
              >
                {children}
              </OrderedList>
            ),
            li: ({ children }) => (
              <ListItem mb={2}>
                <Text
                  fontSize="md"
                  lineHeight="1.5"
                  fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
                >
                  {children}
                </Text>
              </ListItem>
            ),
            h3: ({ children }) => (
              <Heading as="h3" size="md" my={4} fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'>
                {children}
              </Heading>
            ),

            h2: ({ children }) => (
              <Heading as="h3" size="sm" my={3} fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'>
                {children}
              </Heading>
            ),
          }}
        >
          {processedContent.replace(/<sketchpad>.*<\/sketchpad>/s, "").replace(/<sources>.*<\/sources>/, "")}
        </ReactMarkdown>

        {/* Sketchpad Dropdown */}
        {sketchpadContent && (
          <Accordion allowToggle my={4}>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left" fontWeight="bold">
                    Further Explanation
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
              <ReactMarkdown
          components={{
            p: ({ children }) => (
              <Text
                fontSize="md"
                lineHeight="1.5"
                fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
                mb={0}
              >
                {children}
              </Text>
            ),
            strong: ({ children }) => (
              <Text
                as="strong"
                fontWeight="bold"
                fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
              >
                {children}
              </Text>
            ),
            em: ({ children }) => (
              <Text
                as="em"
                fontStyle="italic"
                fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
                my={4}
              >
                {children}
              </Text>
            ),
            code: ({ children }) => {
              const isInline = typeof children === "string" && !children.includes("\n");
                return isInline ? (
                <span
                  style={{
                  backgroundColor: theme.colors.vscode.sidebar,
                  color: "white",
                  fontFamily: "monospace",
                  padding: "2px 4px",
                  borderRadius: "4px",
                  }}
                >
                  {children}
                </span>
                ) : (
                <SyntaxHighlighter
                  customStyle={{ borderRadius: "4px", backgroundColor: theme.colors.vscode.sidebar, color: "white"}}
                  style={atomOneDark}
                >
                  {String(children).trim()}
                </SyntaxHighlighter>
                );
            },
            ul: ({ children }) => (
              <List
                styleType="disc"
                pl={5}
                fontSize="md"
                lineHeight="1.5"
                fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
                my={4}
              >
                {children}
              </List>
            ),
            ol: ({ children }) => (
              <OrderedList
                styleType="decimal"
                pl={5}
                fontSize="md"
                lineHeight="1.5"
                fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
                my={4}
              >
                {children}
              </OrderedList>
            ),
            li: ({ children }) => (
              <ListItem mb={2}>
                <Text
                  fontSize="md"
                  lineHeight="1.5"
                  fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'
                >
                  {children}
                </Text>
              </ListItem>
            ),
            h3: ({ children }) => (
              <Heading as="h3" size="md" my={4} fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'>
                {children}
              </Heading>
            ),

            h2: ({ children }) => (
              <Heading as="h3" size="sm" my={3} fontFamily='"Segoe UI", "Roboto", "Helvetica Neue", sans-serif'>
                {children}
              </Heading>
            ),
          }}
        >{sketchpadContent}</ReactMarkdown>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        )}

        {/* Sources */}
        {sources && (
          <>
           
            <Heading as="h4" size="sm" mb={2}>
              Sources:
            </Heading>
            <List styleType="none" pl={0}>
              {sources.map((source, index) => (
                <ListItem key={index} mb={2}>
                  <Text fontSize="sm">
                    📄 <strong>Source:</strong> {source.source}, <strong>Page:</strong> {source.page}
                  </Text>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Box>
    </Flex>
  );
};

export default Message;




