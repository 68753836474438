import React, { useEffect, useState } from "react";
import apiClient from "../../api/apiClient";
import { Bar, Pie } from "react-chartjs-2";
import {
    Chart,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";

// Register Chart.js components
Chart.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const FeedbackRequests = () => {
    const [feedbackData, setFeedbackData] = useState([]);
    const [feedbackList, setFeedbackList] = useState([]);
    const [robotData, setRobotData] = useState([]);

    useEffect(() => {
        fetchFeedbackCount();
        fetchFeedbackList();
        fetchRobotRequestCount();
    }, []);

    const fetchFeedbackCount = async () => {
        try {
            const countData = await apiClient.get("/feedback/count");
            setFeedbackData(Object.entries(countData).map(([feature, count]) => ({ feature, count })));
        } catch (error) {
            console.error("Error fetching feedback count:", error);
        }
    };

    const fetchFeedbackList = async () => {
        try {
            const data = await apiClient.get("/feedback");
            setFeedbackList(data.slice(0, 5)); // Display only the latest 5 feedbacks
        } catch (error) {
            console.error("Error fetching feedback:", error);
        }
    };

    const fetchRobotRequestCount = async () => {
        try {
            const data = await apiClient.get("/robot-request/count");
            setRobotData(Object.entries(data).map(([brand, count]) => ({ brand, count })));
        } catch (error) {
            console.error("Error fetching robot requests:", error);
        }
    };

    // Data for Feedback Bar Chart
    const feedbackChartData = {
        labels: feedbackData.map(item => item.feature),
        datasets: [
            {
                label: "Feedback Count",
                data: feedbackData.map(item => item.count),
                backgroundColor: "rgba(75,192,192,0.6)",
                borderColor: "rgba(75,192,192,1)",
                borderWidth: 1,
            },
        ],
    };

    // Data for Robot Requests Pie Chart
    const robotChartData = {
        labels: robotData.map(item => item.brand),
        datasets: [
            {
                label: "Robot Requests",
                data: robotData.map(item => item.count),
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4CAF50"],
            },
        ],
    };

    return (
        <div style={{ maxWidth: "900px", margin: "0 auto", padding: "20px", textAlign: "center" }}>
            <h1 style={{ fontSize: "28px", fontWeight: "bold", marginBottom: "20px" }}>Dashboard</h1>

            {/* Feedback Bar Chart */}
            <div style={{ background: "#252526", padding: "20px", borderRadius: "10px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", marginBottom: "30px" }}>
                <h2>Feedback Distribution</h2>
                <Bar data={feedbackChartData} options={{ responsive: true, plugins: { legend: { position: "top" } } }} />
            </div>

            {/* Feedback List */}
            <div style={{ background: "#252526", padding: "20px", borderRadius: "10px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", marginBottom: "30px", textAlign: "left" }}>
                <h2>Recent Feedback</h2>
                <ul style={{ listStyle: "none", padding: "0" }}>
                    {feedbackList.length > 0 ? (
                        feedbackList.map((feedback) => (
                            <li key={feedback.id} style={{ padding: "10px", borderBottom: "1px solid #ddd" }}>
                                <strong>{feedback.feature}:</strong> {feedback.feedback} 
                                <span style={{ fontSize: "12px", color: "gray", marginLeft: "10px" }}>({feedback.timestamp})</span>
                            </li>
                        ))
                    ) : (
                        <p>No feedback available.</p>
                    )}
                </ul>
            </div>

            {/* Robot Requests Pie Chart */}
            <div style={{ background: "#252526", padding: "20px", borderRadius: "10px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", marginBottom: "30px" }}>
                <h2>Robot Request Distribution</h2>
                <Pie data={robotChartData} options={{ responsive: true, plugins: { legend: { position: "right" } } }} />
            </div>
        </div>
    );
};

export default FeedbackRequests;

