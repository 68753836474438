import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement, // Required for pie/doughnut charts
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
  
  // Register required components
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement, // Register "arc" for pie charts
    Title,
    Tooltip,
    Legend
  );
  
  