import React from 'react';
import { Box, Container, Heading, Text, Link } from '@chakra-ui/react';

const Privacy = () => {
  return (
    <Box as="main" py={10}>
      <Container maxW="container.xl">
        <Heading mb={6}>Privacy Policy</Heading>
        <Text mb={4}>
          In order to receive information about your Personal Data, the purposes, and the parties the Data is shared with, contact the Owner.
      </Text>
        
        <Heading size="lg" mt={8} mb={4}>Owner and Data Controller</Heading>
        <Text mb={4}>
          Xelerit GmbH - Winkelriedstrasse 5, Zurich, CH (8006)<br />
          Owner contact email: <Link href="mailto:care@xelerit-robotics.com" color="blue.500">care@xelerit-robotics.com</Link>
        </Text>
        
        <Heading size="lg" mt={8} mb={4}>Types of Data collected</Heading>
        <Text mb={4}>
          The owner does not provide a list of Personal Data types collected. Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Application.Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.Any use of Cookies – or of other tracking tools — by this Application or by the owners of third-party services used by this Application serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy.
          Users are responsible for any third-party Personal Data obtained, published or shared through this Application.
        </Text>
        
        <Heading size="md" mt={8} mb={4}>Mode and Place of Processing the Data</Heading>
        <Text mb={4}>
        The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Application (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.
        </Text>

        <Heading size="md" mt={8} mb={4}>Place</Heading>
        <Text mb={4}>
        The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located.
        Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.

        </Text>
        
        <Heading size="md" mt={8} mb={4}>Retention Time</Heading>
        <Text mb={4}>
        Unless specified otherwise in this document, Personal Data shall be processed and stored for as long as required by the purpose they have been collected for and may be retained for longer due to applicable legal obligation or based on the Users’ consent.
        </Text>
        
        <Heading as="h3" size="md" mt={8} mb={4}>User Rights Under GDPR</Heading>
        <Text mb={4}>
          Users have the right to withdraw consent, object to processing, access their Data, verify and seek rectification, restrict processing, request deletion, receive and transfer their Data, and lodge a complaint.
        </Text>
        
        <Heading size="lg" mt={8} mb={4}>Cookie Policy</Heading>
        <Text mb={4}>
          Xelerit uses cookies to personalize content, save user preferences, and collect statistical data. Users can manage cookie preferences via the consent banner displayed upon accessing the website.
        </Text>


        <Heading size="lg" mt={8} mb={4}>Further information for Users</Heading>
        <Heading as="h3" size="md" mt={8} mb={4}>Legal Basis of Processing</Heading>
        <Text mb={4}>
          The Owner may process Personal Data based on the following conditions:
          <ul>
            <li>Users have provided consent for specific purposes.</li>
            <li>Processing is necessary for contract fulfillment or pre-contractual obligations.</li>
            <li>Processing is required for legal compliance.</li>
            <li>Processing is necessary for the Owner’s legitimate interests.</li>
          </ul>
          If further clarification is needed, users may contact the Owner.
        </Text>

        <Heading as="h3" size="md" mt={8} mb={4}> Further information about Retention Time</Heading>
        <Text mb={4}>
          Personal Data is processed and stored as long as necessary for its purpose. Data may be retained longer due to legal obligations or based on user consent. 
          <ul>
            <li>Data collected for contract fulfillment is retained until completion of the contract.</li>
            <li>Data for legitimate interests is retained as long as necessary.</li>
          </ul>
          Once retention expires, data is deleted, and users cannot request access, rectification, or portability.
        </Text>

        <Heading as="h3" size="md" mt={8} mb={4}>User Rights Under GDPR</Heading>
        <Text mb={4}>
          Users have the right to:
          <ul>
            <li>Withdraw consent at any time.</li>
            <li>Object to data processing under legal bases other than consent.</li>
            <li>Access and obtain copies of their data.</li>
            <li>Verify, update, or correct their data.</li>
            <li>Restrict data processing.</li>
            <li>Request data deletion.</li>
            <li>Receive their data in a structured, machine-readable format and transfer it to another controller.</li>
            <li>Lodge a complaint with a data protection authority.</li>
          </ul>
        </Text>

        <Heading as="h3" size="md" mt={8} mb={4}>Objection to Processing</Heading>
        <Text mb={4}>
          If data is processed for public interest or legitimate interests, users may object based on their specific situation. Users may always object to direct marketing data processing, which will result in the immediate cessation of such processing.
        </Text>

        <Heading as="h3" size="md" mt={8} mb={4}>Exercising User Rights</Heading>
        <Text mb={4}>
          Users can submit requests to exercise their rights via the contact details provided in this document. Requests are free of charge and will be handled within one month.
        </Text>

        <Heading as="h3" size="md" mt={8} mb={4}>Additional Rights for Swiss Users</Heading>
        <Text mb={4}>
          Swiss users have additional rights under the Swiss Federal Act on Data Protection:
          <ul>
            <li>Right to access Personal Data.</li>
            <li>right to object to the processing of their Personal Data (which also allows Users to demand that processing of Personal Data be restricted, Personal Data be deleted or destroyed, specific disclosures of Personal Data to third parties be prohibited);</li>
            <li>right to receive their Personal Data and have it transferred to another controller (data portability);</li>
            <li>Right to request correction of inaccurate data.</li>
          </ul>
          Requests can be made through the contact details in this document.
        </Text>

        <Heading as="h3" size="md" mt={8} mb={4}>Changes to this Privacy Policy</Heading>
        <Text mb={4}>
          The Owner reserves the right to modify this privacy policy at any time. Users will be notified of any changes via updates on this page.
        </Text>

        <Heading as="h3" size="md" mt={8} mb={4}>Contact</Heading>
        <Text mb={4}>
          For any inquiries or concerns, please contact us at: 
          <Link href="mailto:care@xelerit-robotics.com" color="blue.500"> care@xelerit-robotics.com</Link>.
        </Text>
      </Container>
    </Box>
  );
};

export default Privacy;