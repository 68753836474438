import React, { useState } from 'react';
import { translateCode, improveCode } from '../../api/translate';
import {
  Box,
  Textarea,
  Select,
  Button,
  Flex,
  Heading,
  useToast,
  IconButton,
  useClipboard,
  HStack,
} from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

const TranslatePanel = ({onTranslateClick, userId}) => {
  const [inputCode, setInputCode] = useState('');
  const [outputCode, setOutputCode] = useState('');
  const [sourceLanguage, setSourceLanguage] = useState('abb');
  const [targetLanguage, setTargetLanguage] = useState('kuka');
  const [isLoading, setIsLoading] = useState(false);
  const [isImproveLoading, setIsImproveLoading] = useState(false);

  const toast = useToast();
  const { hasCopied, onCopy } = useClipboard(outputCode);

  const handleTranslate = async () => {
    if (!inputCode.trim()) {
      toast({
        title: 'Input code is required',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);

    const payload = {
      code: inputCode,
      from_language: sourceLanguage,
      to_language: targetLanguage,
      user_id : userId,
    };

    try {
      const response = await translateCode(payload);
      console.log(response);
      setOutputCode(response.code.code);
    } catch (error) {
      toast({
        title: 'Translation failed',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      onTranslateClick();
    }
  };

  const handleImprovement = async () => {
    if (!inputCode.trim()) {
      toast({
        title: 'Input code is required',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsImproveLoading(true);

    const payload = {
      code: outputCode,
      from_language: sourceLanguage,
      to_language: targetLanguage,
      user_id : userId,
    };

    try {
      const response = await improveCode(payload);
      console.log(response);
      setOutputCode(response.code.code);
    } catch (error) {
      toast({
        title: 'Translation failed',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsImproveLoading(false);
      onTranslateClick();
    }
  };

  const handleCopy = () => {
    onCopy();
    toast({
      title: 'Copied to clipboard',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Box padding="30" borderRadius="md" shadow="md" height={"100%"}>
      <Heading size="md" mb={4}>
        Code Translator
      </Heading>
      <Flex justifyContent="space-between" alignItems="flex-start" gap={4}>
        {/* Input Panel */}
        <Box flex={1}>
          <Heading size="sm" mb={2}>
            Source Code
          </Heading>
          <HStack justifyContent="flex-end">
          <Select
            value={sourceLanguage}
            onChange={(e) => setSourceLanguage(e.target.value)}
            mt={2}
            mb={2}
          >
            <option value="abb">ABB</option>
            <option value="kuka">KUKA</option>
            <option value="estun">Estun</option>
          </Select>
          </HStack>
          <Textarea
            value={inputCode}
            onChange={(e) => setInputCode(e.target.value)}
            placeholder="Enter your code here"
            rows={30}
            resize="vertical"
          />
        </Box>

        <Flex alignItems="center" justifyContent="center" flexShrink={0}>
          <Button
            bg="xelerit.lightBlue"
            color="white"
            onClick={handleTranslate}
            isLoading={isLoading}
            loadingText="Translating"
          >
            Translate →
          </Button>
        </Flex>

        {/* Output Panel */}
        <Box flex={1}>
          <Heading size="sm" mb={2}>
            Translated Code
          </Heading>
          <HStack justifyContent="flex-end">
          <Select
            value={targetLanguage}
            onChange={(e) => setTargetLanguage(e.target.value)}
            mt={2}
            mb={2}
          >
            <option value="abb">ABB</option>
            <option value="kuka">KUKA</option>
            <option value="estun">Estun</option>
          </Select>
          <Button
            bg="xelerit.orange"
            color="white"
            onClick={handleImprovement}
            isLoading={isImproveLoading}
            loadingText="Translating"
          >
            Improve
          </Button>
          <IconButton
              aria-label="Copy code"
              icon={<CopyIcon />}
              onClick={handleCopy}
              ml={2}
            />
          </HStack>
          <Flex alignItems="center" mb={2}>
            <Textarea
              value={outputCode}
              onChange={(e) => setOutputCode(e.target.value)}
              placeholder="Translated code will appear here"
              rows={30}
              resize="vertical"
            />
            
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default TranslatePanel;
