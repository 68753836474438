import React from "react";
import { VStack, useMediaQuery } from "@chakra-ui/react";
import Message from "./Message.js";


const handleFeedback = (reaction) => {
  console.log("User reacted with:", reaction);
};




const MessageList = ({ messages, isLoading }) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  return (
    <VStack
      spacing={4}
      align="stretch"
      px={isMobile ? 1 : 10} // Adjust padding dynamically
      py={isMobile ? 1 : 4} 
    >
      {messages.map((msg, index) => (
        <Message
          key={index}
          onFeedback={handleFeedback}
          msg={msg}
          isLoading={isLoading}
          isLast={index === messages.length - 1}
        />
      ))}
    </VStack>
  );
};

export default MessageList;
