import React from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Box, Heading, Text, Grid, GridItem } from "@chakra-ui/react";
import { FaComments, FaMicrochip, FaCode, FaFileAlt } from "react-icons/fa";
import Footer from "../../components/Layout/Footer";
import { useTheme } from "@chakra-ui/react";
import CookieBanner from "../../components/CookieBanner";

const PremiumHome = ({ user }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const userId = user?.id || "guest";
  const robotBrand = user?.robotBrand || "estun";

  const services = [
    {
      label: "Chat Service",
      description: "Interact with the AI-powered chat assistant.",
      icon: FaComments,
      path: "/premium/chat",
      color: theme.colors.vscode.sidebar,
      iconColor: theme.colors.xelerit.orange,
      state: { userId, robotBrand },
    },
    {
      label: "I/O Configuration",
      description: "Configure and manage input/output settings.",
      icon: FaMicrochip,
      path: "/premium/io-config",
      color: theme.colors.vscode.sidebar,
      iconColor:theme.colors.xelerit.mediumBlue,
    },
    {
      label: "Code Editor",
      description: "Write, edit, and manage your code in an advanced editor.",
      icon: FaCode,
      path: "/premium/code-editor",
      color: theme.colors.vscode.sidebar,
      iconColor: theme.colors.xelerit.lightBlue,
    },
    {
      label: "Documentation Generation",
      description: "Generate and manage technical documentation.",
      icon: FaFileAlt,
      path: "/premium/documentation",
      color: theme.colors.vscode.sidebar,
      iconColor: "white",
    },
  ];

  return (
    <Flex direction="column" minHeight="100vh" bg={theme.colors.vscode.background} color={theme.colors.vscode.foreground}>
      <Flex flex="1" align="center" justify="center" direction="column" p={8}>
        <Heading mb={8}>Welcome, {user?.username || "Guest"}! Select a Service</Heading>
        <Grid templateColumns="repeat(2, 1fr)" gap={6} maxW="800px">
          {services.map((service) => (
            <GridItem
              key={service.label}
              p={6}
              bg={service.color}
              borderRadius="md"
              boxShadow="md"
              textAlign="center"
              _hover={{ opacity: 0.9 }}
              cursor="pointer"
              onClick={() => navigate(service.path, { state: service.state })}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              {React.createElement(service.icon, { size: 50, style: { marginBottom: "12px", color: service.iconColor } })}
              <Heading size="md" mt={2}>{service.label}</Heading>
              <Text fontSize="sm" mt={1}>{service.description}</Text>
            </GridItem>
          ))}
        </Grid>
      </Flex>
      <Footer />
      <CookieBanner />
    </Flex>
  );
};

export default PremiumHome;
