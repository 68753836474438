import React, { useState, useEffect } from "react";
import { 
    Flex, 
    Box, 
    Heading, 
    Select, 
    Button, 
    IconButton, 
    VStack, 
    useDisclosure, 
    Center, 
    Spinner, 
    Text, 
    Table, 
    Tbody, 
    Td, 
    Th, 
    Thead, 
    Tr, 
    Checkbox, 
    Tooltip,
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalBody, 
    ModalFooter, 
    FormControl, 
    Input
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon, DeleteIcon, EditIcon, PlusSquareIcon } from "@chakra-ui/icons";
import Footer from "../../components/Layout/Footer";
import { useAuth } from "../../hooks/AuthProvider";
import { checkServiceAvailability } from "../../api/variables";
import VariablesPanel from "../../components/panels/variablesPanel";

const IOConfigPage = () => {
    const { user } = useAuth();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedRobotBrand, setSelectedRobotBrand] = useState("estun");
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const [isServiceAvailable, setIsServiceAvailable] = useState(null);
    const [plcManufacturer, setPlcManufacturer] = useState("siemens");
    
    useEffect(() => {
        const validateServiceAvailability = async () => {
            setLoading(true);
            try {
                const response = await checkServiceAvailability(plcManufacturer, selectedRobotBrand);
                setIsServiceAvailable(response.available);
            } catch (error) {
                console.error("Error validating service availability:", error);
                setIsServiceAvailable(false);
            } finally {
                setLoading(false);
            }
        };
        
        if (plcManufacturer && selectedRobotBrand) {
            validateServiceAvailability();
        } else {
            setIsServiceAvailable(false);
            setLoading(false);
        }
    }, [plcManufacturer, selectedRobotBrand]);
    
    if (loading) {
        return (
            <Center height="100vh">
                <Spinner size="lg" />
            </Center>
        );
    }
    
    if (!isServiceAvailable) {
        return (
            <Center height="100vh">
                <Box textAlign="center">
                    <Heading size="md" mb={4}>Variable Service Unavailable</Heading>
                    <Text>The variable service is currently under development for the selected PLC and robot.</Text>
                </Box>
            </Center>
        );
    }
    
    return (
        <Flex direction="column" minHeight="100vh">
            <Flex align="center" justify="center" direction="column">
                <Flex width="100%" height="97vh">
                    {/* Sidebar */}
                    <Box
                        width={isSidebarOpen ? "250px" : "50px"}
                        p={isSidebarOpen ? 4 : 2}
                        bg="vscode.sidebar"
                        transition="width 0.3s ease-in-out"
                        overflow="hidden"
                    >
                        <Flex justifyContent="space-between" alignItems="center" mb={4}>
                            {isSidebarOpen && <Heading size="sm">Settings</Heading>}
                            <IconButton 
                                icon={isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />} 
                                onClick={() => setIsSidebarOpen(!isSidebarOpen)} 
                                size="sm"
                                bg="transparent"
                                _hover={{ bg: "gray.700" }}
                            />
                        </Flex>
                        {isSidebarOpen && (
                            <>
                                <Text fontSize="sm" color="gray.400" mb={2}>PLC Manufacturer</Text>

                                <Select
                                        width="100%"
                                        placeholder="Select Robot Brand"
                                        value={plcManufacturer}
                                        onChange={(e) => setPlcManufacturer(e.target.value)}
                                        size="sm"
                                        color="white"
                                        mb={4}
                                    >
                                        <option value="siemens">Siemens</option>
                                    </Select>
                                    <Text fontSize="sm" color="gray.400" mb={2}>Robot Brand</Text>
                                <Select
                                    width="100%"
                                    placeholder="Select Robot Brand"
                                    value={selectedRobotBrand}
                                    onChange={(e) => setSelectedRobotBrand(e.target.value)}
                                    size="sm"
                                    
                                    color="white"
                                >
                                    <option value="estun">Estun</option>
                                    
                                </Select>
                                
                            </>
                        )}
                    </Box>
                    
                    {/* Content Area */}
                    <Box flex="1" mt={10}>
                    <VariablesPanel
                        plcManufacturer={plcManufacturer}
                        robotBrand={selectedRobotBrand}
                        />
                      
                    </Box>
                </Flex>
            </Flex>
            <Footer />
        </Flex>
    );
};

export default IOConfigPage;
