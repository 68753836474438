import React, { useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
  Text,
  Checkbox,
  HStack,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useAuth } from "../hooks/AuthProvider";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import { Link as RouterLink } from "react-router-dom";
const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [input, setInput] = useState({ username: "", password: "" });
  const [error, setError] = useState(null);
  const [isChecked, setIsChecked] = useState(false); // State for checkbox
  const auth = useAuth();
  const navigate = useNavigate();

  const handleShowClick = () => setShowPassword(!showPassword);

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    setError(null);
  
    if (!isChecked) {
      setError("You must accept the terms and conditions.");
      return;
    }
  
    if (input.username && input.password) {
      try {
        await auth.loginAction(input);
  
        // Retrieve and parse user data from localStorage
        let user;
        try {
          user = JSON.parse(localStorage.getItem("user") || "{}");
        } catch (err) {
          console.error("Error parsing user data:", err);
          setError("Login failed. Please try again.");
          return;
        }
  
        // Navigate based on role
        switch (user?.role) {
          case "admin":
            navigate("/admin-dashboard");
            break;
          case "premium":
            navigate("/premium-home");
            break;
          case "base":
            navigate("/base-home");
            break;
          default:
            navigate("/unauthorized");
        }
      } catch (err) {
        setError("Invalid username or password. Please try again.");
        console.error("Login error:", err.message);
      }
    } else {
      setError("Please fill out all fields.");
    }
  };
  

  return (
    <Box>
    <Flex
      flexDirection="column"
      width="100wh"
      height="97vh"
      backgroundColor="xelerit.background"
      justifyContent="center"
      alignItems="center"
    >
      <Stack flexDir="column" mb="2" justifyContent="center" alignItems="center">
        <Avatar bg="xelerit.lightBlue" />
        <Heading color="xelerit.lightBlue">Welcome to Xelerit</Heading>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={handleSubmitEvent}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="vscode.sidebar"
              boxShadow="md"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="vscode.accent" />}
                  />
                  <Input
                    type="text"
                    placeholder="Username"
                    value={input.username}
                    onChange={(e) =>
                      setInput({ ...input, username: e.target.value })
                    }
                    required
                    color="vscode.foreground"
                    bg="vscode.sidebar"
                    _placeholder={{ color: "vscode.secondary" }}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaLock color="vscode.accent" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={input.password}
                    onChange={(e) =>
                      setInput({ ...input, password: e.target.value })
                    }
                    required
                    color="vscode.foreground"
                    bg="vscode.sidebar"
                    _placeholder={{ color: "vscode.secondary" }}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={handleShowClick}
                      color={"vscode.background"}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              {/* Checkbox for Terms and Conditions */}
              <FormControl>
              <Box width={"400px"} ml={2}>
                <HStack justifyContent={"space-between"} alignItems="flex-start" spacing={2}>
                  <Checkbox
                    isChecked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                    colorScheme="xelerit.lightBlue"
                  >
                    <Box ml={4}> {/* Add margin or spacing */}
                      I accept the{" "}
                      <Link href="/terms" color="xelerit.lightBlue" isExternal>
                        Terms and Conditions
                      </Link>
                    </Box>
                  </Checkbox>
                </HStack>
              </Box>
            </FormControl>


              {error && <Text color="red.500" as="i">{error}</Text>}
              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                bg="xelerit.lightBlue"
                color="white"
                _hover={{ bg: "vscode.accent" }}
                width="full"
              >
                Login
              </Button>
              
            </Stack>
          </form>

          <Box textAlign="center" mt={4}>
          <Text>
            Don’t have an account?{" "}
            <Link as={RouterLink} to="/signup" color="xelerit.lightBlue">
              Sign Up
            </Link>
          </Text>
        </Box>
          
        </Box>
      </Stack>
      
    </Flex>
    <Footer />
    </Box>
  );
};

export default Login;


