import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  SimpleGrid,
  Spinner,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useTheme,
  Checkbox,
  CheckboxGroup,
  Collapse,
  Button,
  VStack,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Line, Pie, Bar } from "react-chartjs-2";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import useChatLogs from "../../hooks/useChatLogs";
import { aggregateChatData } from "../../utils/aggregateChatData";

const ChatStats = () => {
  const { chatLogs, loading } = useChatLogs();
  const theme = useTheme();
  const colors = theme.colors;

  // State for Filters
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dateRange, setDateRange] = useState({ from: "", to: "" });
  const [showBrandFilter, setShowBrandFilter] = useState(false);
  const [showUserFilter, setShowUserFilter] = useState(false);

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" color="xelerit.lightBlue" />
      </Flex>
    );
  }

  // Compute aggregated data
  const aggregatedData = aggregateChatData(chatLogs);

  // Filtering Logic
  const filteredLogs = chatLogs.filter((log) => {
    const logDate = new Date(log.timestamp).toISOString().split("T")[0];

    const matchesBrand =
      selectedBrands.length === 0 || selectedBrands.includes(log.robot_brand_id);

    const matchesUser =
      selectedUsers.length === 0 || selectedUsers.includes(log.user_id.toString());

    const matchesDate =
      (!dateRange.from || logDate >= dateRange.from) &&
      (!dateRange.to || logDate <= dateRange.to);

    return matchesBrand && matchesUser && matchesDate;
  });

  // Recompute Aggregated Data After Filtering
  const {
    totalInteractions,
    totalUsers,
    interactionsPerDay,
    averageResponseTimePerDay,
    robotBrandSplit,
    userActivity,
    mostUsedRobotBrand,
    mostFrequentQuestions,
    questionsPerDay,
  } = aggregateChatData(filteredLogs);

  return (
    <Box p={8}>
      <Heading mb={6}>Chat Service Stats</Heading>

      {/* FILTERS SECTION */}
      <Box p={6} bg="vscode.sidebar" borderRadius="lg" boxShadow="lg" mb={6}>
        <Heading size="md" mb={4}>Filters</Heading>
        <Grid templateColumns={["1fr", "1fr", "1fr 1fr"]} gap={6}>
          {/* Date Range Filters */}
          <GridItem>
            <Heading size="xs" mb={2}>Date Range</Heading>
            <Flex gap={2}>
              <Input type="date" onChange={(e) => setDateRange({ ...dateRange, from: e.target.value })} />
              <Input type="date" onChange={(e) => setDateRange({ ...dateRange, to: e.target.value })} />
            </Flex>
          </GridItem>
        </Grid>

        {/* Expandable Filters */}
        <VStack mt={4} align="start">
          {/* Robot Brand Filter */}
          <Button
            onClick={() => setShowBrandFilter(!showBrandFilter)}
            rightIcon={showBrandFilter ? <ChevronUpIcon /> : <ChevronDownIcon />}
            size="sm"
            variant="outline"
            width="100%"
            color="white"
            _hover= {{ bg: "xelerit.lightBlue" }}
          >
            Filter by Robot Brand
          </Button>
          <Collapse in={showBrandFilter}>
            <CheckboxGroup onChange={setSelectedBrands}>
              <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                {Object.keys(robotBrandSplit).map((brand) => (
                  <Checkbox key={brand} value={brand}>
                    {brand}
                  </Checkbox>
                ))}
              </Grid>
            </CheckboxGroup>
          </Collapse>

          {/* User Filter */}
          <Button
            onClick={() => setShowUserFilter(!showUserFilter)}
            rightIcon={showUserFilter ? <ChevronUpIcon /> : <ChevronDownIcon />}
            size="sm"
            variant="outline"
            width="100%"
            color="white"
            _hover= {{ bg: "xelerit.lightBlue" }}
          >
            Filter by Users
          </Button>
          <Collapse in={showUserFilter}>
            <CheckboxGroup onChange={setSelectedUsers}>
              <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                {Array.from(new Set(chatLogs.map((log) => log.user_id))).map((user) => (
                  <Checkbox key={user} value={user.toString()}>
                    {user}
                  </Checkbox>
                ))}
              </Grid>
            </CheckboxGroup>
          </Collapse>
        </VStack>
      </Box>

      {/* METRICS SECTION */}
      <SimpleGrid columns={[1, 2, 3]} spacing={6} mb={16} mt={8}>
        <Stat>
          <StatLabel>Total Interactions</StatLabel>
          <StatNumber>{totalInteractions}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Total Users</StatLabel>
          <StatNumber>{totalUsers}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Most Used Robot Brand</StatLabel>
          <StatNumber>{mostUsedRobotBrand || "N/A"}</StatNumber>
        </Stat>
      </SimpleGrid>

      {/* CHARTS SECTION */}
      <SimpleGrid columns={[1, 2, 2]} spacing={10}>
        <Box>
          <Line
            data={{
              labels: Object.keys(interactionsPerDay),
              datasets: [
                {
                  label: "Daily Interactions",
                  data: Object.values(interactionsPerDay).map((logs) => logs.length),
                  borderColor: colors.xelerit.orange,
                },
              ],
            }}
          />
        </Box>

        <Box maxWidth="300px" maxHeight="300px" mx="auto">
          <Pie
            data={{
              labels: Object.keys(robotBrandSplit),
              datasets: [
                {
                  label: "Robot Brand Split",
                  data: Object.values(robotBrandSplit),
                  backgroundColor: [colors.xelerit.lightBlue, colors.xelerit.orange, colors.xelerit.grey],
                },
              ],
            }}
          />
        </Box>

        <Box>
          <Bar
            data={{
              labels: Object.keys(averageResponseTimePerDay),
              datasets: [
                {
                  label: "Average Response Time per Day",
                  data: Object.values(averageResponseTimePerDay),
                  backgroundColor: colors.xelerit.mediumBlue,
                },
              ],
            }}
          />
        </Box>

        <Box>
          <Bar
            data={{
              labels: Object.keys(userActivity),
              datasets: [
                {
                  label: "User Interactions",
                  data: Object.values(userActivity),
                  backgroundColor: colors.xelerit.grey,
                },
              ],
            }}
          />
        </Box>

       
      </SimpleGrid>

     
      
    </Box>
  );
};

export default ChatStats;




