import React, { useState } from 'react';
import {
  Box,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Flex,
  Text,
  ModalCloseButton,
} from '@chakra-ui/react';
import mammoth from 'mammoth';

const UploadRequirements = ({ isOpen, onClose, onUpload }) => {
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();

      // Handle .docx files with Mammoth.js
      if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        reader.onload = async () => {
          try {
            const { value: docText } = await mammoth.extractRawText({
              arrayBuffer: reader.result,
            });
            setFilePreview(docText);
          } catch (error) {
            setFilePreview('Error extracting .docx content.');
            console.error('Error processing .docx file:', error);
          }
        };
        reader.readAsArrayBuffer(selectedFile);
      }

      // Preview for text-based files
      else if (selectedFile.type.includes('text') || selectedFile.type === 'application/pdf') {
        reader.onload = () => setFilePreview(reader.result);
        reader.readAsText(selectedFile);
      }

      // If it's an image, show it directly
      else if (selectedFile.type.includes('image')) {
        reader.onload = () => setFilePreview(reader.result);
        reader.readAsDataURL(selectedFile);
      } else {
        setFilePreview('Preview not available for this file type.');
      }
    }
  };

  const handleFileUpload = async () => {
    if (file) {
      setIsUploading(true);
      try {

        const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        await wait(1000);
        
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setIsUploading(false);
        onUpload(file); 
        onClose();
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"} >
      <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(5px)" />
      <ModalContent bg="vscode.sidebar">
        <ModalHeader>Upload Project Design</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Text fontSize="sm" mb={4}>
              Select a Word/PDF/Text file to upload. A preview will be shown below.
            </Text>
            <Flex justify="center" mt={4}>
              <Input type="file" onChange={handleFileChange} />
            </Flex>
            {filePreview && (
              <Box mt={4} p={4} bg="vscode.background" color={"white"} borderRadius="md" maxH="400px" overflowY="auto">
                <Text fontSize="sm" whiteSpace="pre-wrap">
                  {filePreview}
                </Text>
              </Box>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="xelerit.lightBlue"
            color="white"
            mr={3}
            onClick={handleFileUpload}
            isLoading={isUploading}
            isDisabled={isUploading || !file}
          >
            Upload
          </Button>
          <Button bg="white" color="vscode.sidebar" onClick={onClose}>
            Skip for now
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UploadRequirements;