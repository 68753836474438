import React, { useState } from "react";
import {
  Box, Button, Input, VStack, Text, Select, useToast, HStack, List, ListItem, Heading, Textarea, Checkbox, useDisclosure, IconButton, Collapse, Stepper, Step, StepIndicator, StepStatus, StepTitle, StepDescription, StepSeparator,
StepIcon, StepNumber, Center,
Divider, Spinner, Icon,
Spacer} from "@chakra-ui/react";
import { SearchIcon, HamburgerIcon, CloseIcon, CheckIcon } from "@chakra-ui/icons";
import DocPreview from "../../components/premium/DocPreview";
import { FaUpload } from "react-icons/fa";
import { sendZipCode } from "../../api/docs";
import { NeverCompare } from "three";
import { generatePDF, downloadCompiledPdf, downloadWordFile} from "../../components/premium/PdfDowload";
import Footer from "../../components/Layout/Footer";

const DocumentationPage = () => {
    const [file, setFile] = useState(null);
    const [robotBrand, setRobotBrand] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const [existingProject, setExistingProject] = useState("");
    const [changeComment, setChangeComment] = useState("");
    const [responseData, setResponseData] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [isUploadLoading, setIsUploadLoading] = useState(false);

    const projects = ["Project Alpha", "Project Beta", "Project Gamma", "Project Delta", "Project Epsilon"];
    const robotBrands = ["ABB"];

    const [step, setStep] = useState(0);
    const nextStep = () => setStep((prev) => Math.min(prev + 1, 2));
    const prevStep = () => setStep((prev) => Math.max(prev - 1, 0));

    const steps = [
      { title: "Upload Code", description: "File Zip" },
      { title: "Review & Edit", description: "Modify preview" },
      { title: "Download", description: "Get your doc" },
    ];

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            if (selectedFile.type === "application/zip") {
                setFile(selectedFile);
                toast({
                    title: "File selected",
                    description: selectedFile.name,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Invalid file type",
                    description: "Please upload a ZIP file.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    const handleUpload = async () => {
        if (!file || !robotBrand) {
            toast({
                title: "Missing selection",
                description: "Please select a robot brand and upload a file.",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        setIsUploadLoading(true);

        try {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("robotBrand", robotBrand);
            if (isUpdate) {
                formData.append("existingProject", existingProject);
                formData.append("changeComment", changeComment);
            }

            const response = await sendZipCode(formData);
            console.log("Response:", response);

            if (response) {
                setResponseData(response.data);
                nextStep();
                setIsUploadLoading(false);
                
            } else {
                toast({
                    title: "Upload failed",
                    description: "There was an issue uploading the file. Please try again.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Upload error",
                description: "An error occurred while uploading the file. Please try again.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };
    const handleDrop = (event) => {
      event.preventDefault();
      const droppedFile = event.dataTransfer.files[0];
      if (droppedFile && droppedFile.type === "application/zip") {
        setFile(droppedFile);
        toast({
          title: "File selected",
          description: droppedFile.name,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Invalid file type",
          description: "Please upload a ZIP file.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
  
    const handleDragOver = (event) => {
      event.preventDefault();
    };

    const renderStepContent = () => {
      switch (step) {
          case 0:
            return (
            <VStack spacing={6} align="center" width={"80%"}>
              <HStack spacing={4} alignItems="center">
                <Heading size="md" flexShrink={0}>Robot Brand:</Heading>
                <Select placeholder="Select Robot Brand" value={robotBrand} onChange={(e) => setRobotBrand(e.target.value)} flex={1}>
                  {robotBrands.map((brand) => (
                    <option key={brand} value={brand}>{brand}</option>
                  ))}
                </Select>
              </HStack>

              <Box
                p={6}
                border="2px dashed #ccc"
                borderRadius="md"
                textAlign="center"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                cursor="pointer"
                w="100%"
                minHeight="300px"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                position="relative"
                onClick={() => document.getElementById("file-upload").click()} // Ensures click triggers file selection
              >
                <Icon as={FaUpload} boxSize={24} color="xelerit.lightBlue" />
                <Text mt={2}>Drag and drop a ZIP file here, or click to select a file</Text>
                
                {/* Hidden Input for File Selection */}
                <Input
                  type="file"
                  accept=".zip"
                  id="file-upload"
                  display="none"
                  onChange={handleFileChange}
                />
              </Box>

                {/* <Checkbox isChecked={isUpdate} onChange={(e) => setIsUpdate(e.target.checked)}>Updating an Existing Project?</Checkbox>
            {isUpdate && (
              <VStack w="80%" spacing={4}>
                <Select placeholder="Select Existing Project" value={existingProject} onChange={(e) => setExistingProject(e.target.value)}>
                  {["Project Alpha", "Project Beta", "Project Gamma", "Project Delta", "Project Epsilon"].map((project) => (
                    <option key={project} value={project}>{project}</option>
                  ))}
                </Select>
                <Textarea placeholder="Describe what changed" value={changeComment} onChange={(e) => setChangeComment(e.target.value)} />
              </VStack>
            )} */}


              {isUploadLoading ? (
                <VStack>
                <HStack>
                <Spinner size="lg" />
                <Text>Uploading. It will take 2-3 min...</Text>
                </HStack>
                <Text italic color={"red"}>Do not refresh the page</Text>
                </VStack>
              ) : (
                <Button bg="xelerit.lightBlue" color="white" onClick={handleUpload}>Next</Button>
              )}
            </VStack>
              
              );
          case 1:
              return (
                  <>
                      <Heading size="lg">Review & Edit</Heading>
                      <DocPreview isOpen={isOpen} onClose={onClose} previewData={responseData} setPreviewData={setResponseData} />
                      <Button onClick={prevStep}>Back</Button>
                      <Button onClick={nextStep}>Next</Button>
                  </>
              );
              case 2:
                return (
                  <>
                    <Heading size="lg">Download</Heading>
                    <Text>Robot Brand: {robotBrand}</Text>
                    {file && <Text>File: {file.name}</Text>}
                    <HStack spacing={4}>
                      <Button onClick={prevStep}>Back</Button>
                      <Button color="white" bg="xelerit.lightBlue" onClick={() => downloadWordFile(responseData)}>
                        Download Word file
                      </Button>
                    </HStack>
                  </>
                );
          default:
              return null;
      }
  };

    return (
      <Box >
      <HStack spacing={6} align="start" w="100vw" h="97vh">
      {/* Sidebar */}
      <VStack w={isSidebarOpen ? "20%" : "5%"} spacing={4} align="stretch" p={4} h="full" bg="vscode.sidebar">
        <HStack justifyContent="space-between">
        {isSidebarOpen && <Heading size="lg">Projects</Heading>}
        <IconButton
          icon={isSidebarOpen ? <CloseIcon /> : <SearchIcon />}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          aria-label="Toggle sidebar"
        />
        </HStack>
        <HStack>
        {isSidebarOpen ? (
          <Input
          placeholder="Search Projects..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          />
        ) : (
          <>
          </>
        )}
        </HStack>
        {/* <Collapse in={isSidebarOpen}>
        <List spacing={2}>
          {projects.filter(project => project.toLowerCase().includes(searchTerm.toLowerCase())).map((project, index) => (
          <ListItem key={index} p={2} borderRadius="md" cursor="pointer">{project}</ListItem>
          ))}
        </List>
        </Collapse> */}
      </VStack>

      {/* Main content */}
      <VStack spacing={8} p={10} w="100%" h="full">
        {/* <Heading size="lg">Upload a New Codebase and Get the Documentation</Heading> */}
        <Stepper size='lg' borderColor="xelerit.orange" index={step} mt={10}>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator sx={{
              '[data-status=complete] &': {
                background: 'xelerit.orange',
                borderColor: 'black',
              },
              '[data-status=active] &': {
                borderColor: 'xelerit.orange',
              },
              '[data-status=incomplete] &': {
                borderColor: 'xelerit.orange',
              },
            }}>
            <StepStatus
              complete={<CheckIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box flexShrink='0'>
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>{step.description}</StepDescription>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>

    <Box w="70%" padding={10} borderRadius="xl" shadow="lg" bg="vscode.sidebar">
                <VStack spacing={8}>{renderStepContent()}</VStack>
      </Box>
      </VStack>
      
      </HStack>

      </Box>
      
    );
};

export default DocumentationPage;

