import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton, Button, Heading, Text } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const helpSteps = [
  { title: "Click on info icon to get description of each node", img: "/step1_graphstructure.jpeg", description: "Click on info icon on the top right of each node" },
  { title: "Click on node to visulize connections between nodes", img: "/step2_graphstructure.jpeg", description: "Click on a node to check to which other components it is connected" },

];


const HelpGraphModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(5px)" />
      <ModalContent bg="vscode.sidebar">
        <ModalHeader>Interactive Codebase</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Swiper modules={[Navigation, Pagination]} navigation pagination={{ clickable: true }} className="w-full">
            {helpSteps.map((step, index) => (
              <SwiperSlide key={index} className="flex flex-col items-center text-center ">
                <Heading>{step.title}</Heading>
                <img src={step.img} alt={step.title} className="w-full h-48 object-contain my-4" />
              </SwiperSlide>
            ))}
          </Swiper>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" color="white" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default HelpGraphModal;
