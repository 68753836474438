// JoyRideTour.js
import React, { useState } from "react";
import Joyride from "react-joyride";

// Define your default styles
const defaultStyles = {
  options: {
    zIndex: 10000,
    primaryColor: "#FF8500",       // Primary color for buttons, etc.
    textColor: "#ffffff",          // Text color
    backgroundColor: "#252526",    // Tooltip background
    arrowColor: "#252526",         // Tooltip arrow color
    width: 300,                   // Tooltip width
    borderRadius: 8,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  },
  buttonNext: {
    backgroundColor: "#FF8500",
    color: "#ffffff",
    __hover: {
      backgroundColor: "#d4d4d4",
      color: "#000000",
    }
  },
  buttonBack: {
    display: 'none'
  },
  buttonSkip: {
    color: "#ffffff",
  },
};

const JoyRideTour = ({
  steps = [],
  run = true,
  continuous = true,
  showSkipButton = true,
  initialStep = 0,
  onTourCallback = () => {},
  // Allow passing in custom styles as a prop
  styles: customStyles = {},
  onActivePanelChange,
}) => {
  const [stepIndex, setStepIndex] = useState(0);

  // Merge default styles with any custom styles provided.
  // This example uses a shallow merge for nested objects; for deeply nested objects,
  // you might consider a deep merge utility.
  const mergedStyles = {
    ...defaultStyles,
    ...customStyles,
    options: {
      ...defaultStyles.options,
      ...(customStyles.options || {}),
    },
    buttonNext: {
      ...defaultStyles.buttonNext,
      ...(customStyles.buttonNext || {}),
    },
    buttonBack: {
      ...defaultStyles.buttonBack,
      ...(customStyles.buttonBack || {}),
    },
    buttonSkip: {
      ...defaultStyles.buttonSkip,
      ...(customStyles.buttonSkip || {}),
    },
  };
  
  

  const handleCallback = (data) => {
    const { index, type, action } = data;
    
    // Check if we're on the final step and the user pressed "next"
    if (type === 'step:after' && action === 'next' && index === steps.length - 1) {
      // Notify parent that the tour has ended.
      onTourCallback({ ...data, type: 'tour:end' });
      return;
    }
    
    // Existing logic for other steps...
    if (type === 'step:after' && action === 'next') {
      // Handle custom logic for specific steps if needed
      if (index + 1 === 1) {
        onActivePanelChange("chat");
        setTimeout(() => {
          setStepIndex(index + 1);
        }, 500);
        return;
      }
      if (index + 1 === 2) {
        console.log("Next pressed on step", index, "so switching to flow diagram panel...");
        onActivePanelChange("flowdiagram");
        setTimeout(() => {
          setStepIndex(index + 1);
        }, 500);
        return;
      }
    }
    
    // Optionally forward the callback data
    onTourCallback(data);
  };
  
  

  return (
    <Joyride
    steps={steps}
    run={run}
    continuous={continuous}
    showSkipButton={showSkipButton}
    stepIndex={stepIndex}
    callback={handleCallback}
    disableBeacon={true} // Add this line to disable the beacon
    styles={mergedStyles}
    scrollToFirstStep
    hideCloseButton
    
    />
  );
};

export default JoyRideTour;
