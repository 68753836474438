import React, { useState } from "react";
import ChatPanel from "../../components/panels/chatPanel";
import { 
    Flex, 
    Box, 
    Heading, 
    Select, 
    Button, 
    IconButton, 
    useDisclosure,
    VStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useTheme } from "@chakra-ui/react";
import Footer from "../../components/Layout/Footer";
import { useAuth } from "../../hooks/AuthProvider";
import { sendRobotRequest } from "../../api/feedback";

const ChatPage = () => {
    const theme = useTheme();
    const { user, logOut } = useAuth();
    const [questionCount, setQuestionCount] = useState(0);
    const [selectedRobotBrand, setSelectedRobotBrand] = useState("abb");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const { isOpen: isRequestOpen, onOpen: onRequestOpen, onClose: onRequestClose } = useDisclosure();
    const [robotRequest, setRobotRequest] = useState("");  // <-- Fixed: Added missing state

    const handleQuestionAsked = () => {
        setQuestionCount(prevCount => prevCount + 1);
    };

    const handleSendRequest = async () => {
        const payload = {
          user_id: user.id,  // <-- Fixed: Use 'user.id' instead of 'parsedUser.id'
          robot_brand: robotRequest,
        };
      
        console.log("Request Payload:", payload);
      
        try {
          const response = await sendRobotRequest(payload);
          console.log("Response:", response);
        } catch (error) {
          console.error("Error sending request:", error);
        } finally {
          onRequestClose();
        }
    };

    const handleRobotBrandChange = (event) => {
        setSelectedRobotBrand(event.target.value);
    };

    return (
        <Flex direction="column" minHeight="100vh" bg={theme.colors.vscode.background} color={theme.colors.vscode.foreground}>
            <Flex align="center" justify="center" direction="column">
                <Flex width="100%" height="97vh">
                    {/* Sidebar */}
                    <Box
                        width={isSidebarOpen ? { base: "100%", md: "250px" } : "50px"}
                        p={isSidebarOpen ? 4 : 2}
                        bg={theme.colors.vscode.sidebar}
                        transition="width 0.3s ease-in-out"
                        overflow="hidden"
                    >
                        <Flex justifyContent="space-between" alignItems="center" mb={4}>
                            {isSidebarOpen && <Heading size="sm">Settings</Heading>}
                            <IconButton 
                                icon={isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />} 
                                onClick={() => setIsSidebarOpen(!isSidebarOpen)} 
                                size="sm"
                                bg="transparent"
                                _hover={{ bg: "gray.700" }}
                            />
                        </Flex>
                        {isSidebarOpen && (
                            <>
                                <Select
                                    width="100%"
                                    placeholder="Select Robot Brand"
                                    value={selectedRobotBrand}
                                    onChange={handleRobotBrandChange}
                                    size="sm"
                                    bg="vscode.background"
                                    color="white"
                                >
                                    <option value="estun">Estun</option>
                                    <option value="kuka">KUKA</option>
                                    <option value="abb">ABB</option>
                                </Select>
                                <Button
                                    size="sm"
                                    width="100%"
                                    bg="vscode.sidebar"
                                    color="white"
                                    onClick={onRequestOpen}  // <-- Fixed: Changed 'nClick' to 'onClick'
                                    _hover={{ bg: "white", color: "black" }}
                                    mt={4}
                                >
                                    Request Another Robot
                                </Button>
                            </>
                        )}
                    </Box>
                    
                    {/* Chat Panel */}
                    <Box flex="1" mt={10} >
                        <VStack justifyContent={"center"}>
                            <ChatPanel robotBrand={selectedRobotBrand} onQuestionAsked={handleQuestionAsked} userId={user?.id} />
                        </VStack>
                    </Box>
                </Flex>
            </Flex>
            <Footer />
            
            {/* Modal for Requesting Another Robot */}
            <Modal isOpen={isRequestOpen} onClose={onRequestClose} isCentered>
                <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(5px)" />
                <ModalContent bg="vscode.sidebar">
                    <ModalHeader>Request Another Robot</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Provide details about the robot you want to request.</Text>
                        <Select 
                            placeholder="Select Robot Brand" 
                            mt={4} 
                            mb={4} 
                            value={robotRequest} // <-- Fixed: Set state value correctly
                            onChange={(e) => setRobotRequest(e.target.value)} // <-- Fixed: Define state updater
                        >
                            {[
                                "Fanuc", "Yaskawa", "Universal Robots", "Epson", "Staubli", 
                                "Comau", "Omron", "Nachi", "Denso", "Kawasaki", "Doosan", 
                                "Hanwha", "Schunk", "Festo", "Techman", "Reis"
                            ].sort().map((brand) => (
                                <option key={brand} value={brand}>
                                    {brand.charAt(0).toUpperCase() + brand.slice(1)}
                                </option>
                            ))}
                        </Select>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" color="white" mr={3} onClick={onRequestClose}>
                            Close
                        </Button>
                        <Button 
                            bgColor="xelerit.lightBlue" 
                            color="white" 
                            onClick={handleSendRequest}
                            isDisabled={!robotRequest} // <-- Prevent sending empty requests
                        >
                            Submit Request
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default ChatPage;
