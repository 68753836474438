import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactFlow, {
  ReactFlowProvider,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  Handle,
  Position,
  MarkerType,
  useNodesInitialized,
} from "reactflow";
import "reactflow/dist/style.css";
import { Box, Tooltip, IconButton, Button } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import dagre from "dagre";
import html2canvas from "html2canvas";
import domtoimage from "dom-to-image";
import { useReactFlow } from "react-flow-renderer";

// -------------------------------------------------------------------
// options
const options = { includeHiddenNodes: false };

// -------------------------------------------------------------------
// Custom Theme
const theme = {
  background: "#F7F9FB",
  groupBorder: "rgba(44, 62, 80, 0.5)",
  functionNode: "#0a81b4",
  procedureNode: "#081f2d",
  textColor: "#2C3E50",
  edgeColor: "#7F8C8D",
  groupBackground: "rgba(0, 0, 0, 0.05)",
};

// -------------------------------------------------------------------
// Group Node (for states)
const GroupNode = ({ data }) => (
  <div
    style={{
      padding: "10px",
      borderRadius: "8px",
      background: data.background || "#fff",
      border: "1px solid rgba(0, 0, 0, 0.2)",
      textAlign: "center",
      fontWeight: "bold",
    }}
  >
    {data.label}
    {/* Add a hidden target handle for edge connections */}
    <Handle type="source" position={Position.Bottom}  />
    <Handle type="target" position={Position.Top} />
  </div>
);


// -------------------------------------------------------------------
// Custom Node (for individual functions/procedures)
const CustomNode = ({ data }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" position="relative">
      <Box
        bg={data.cat === "function" ? theme.procedureNode : theme.functionNode}
        p="15px"
        borderRadius="50%"
        color="white"
        w="90px"
        h="90px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="12px"
        textAlign="center"
        fontWeight="bold"
        boxShadow="lg"
        position="relative"
      >
        {data.label}
        <Tooltip label={data.description} fontSize="xs" placement="right" hasArrow bg="gray.100" color="black">
          <IconButton
            icon={<InfoIcon />}
            size="sm"
            position="absolute"
            right="-12px"
            top="-12px"
            color="gray.500"
            aria-label="Info"
          />
        </Tooltip>
      </Box>
      <Box
        mt="-5px"
        px="10px"
        py="3px"
        border="2px solid"
        borderColor={data.cat === "function" ? theme.procedureNode : theme.functionNode}
        borderRadius="4px"
        fontSize="8px"
        fontWeight="bold"
        color="black"
        bg="white"
      >
        {data.file}
      </Box>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
    </Box>
  );
};

const nodeTypes = {
  group: GroupNode,
  function: CustomNode,
};

// -------------------------------------------------------------------
// Graph Visualization Component
const GraphVisualization = ({jsonData, onCapture }) => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [selectedNode, setSelectedNode] = useState(null);
    const nodesRef = useRef(nodes);
    useEffect(() => {
      nodesRef.current = nodes;
    }, [nodes]);

    
   

    const containerRef = useRef(null);
    useEffect(() => {
        const captureScreenshot = async () => {
          if (!containerRef.current) return;
          try {
            const dataUrl = await domtoimage.toPng(containerRef.current, {
              quality: 1,
            });
             // Second capture
            const dataUrl2 = await domtoimage.toPng(containerRef.current, {
              quality: 1,
            });
            // Pass the captured image data URL to the parent
            onCapture(dataUrl2);
          } catch (error) {
            console.error("Error capturing screenshot:", error);
          }
        };
    
        // Capture screenshot after component mounts (adjust delay if needed)
        const timer = setTimeout(captureScreenshot, 1000);
        return () => clearTimeout(timer);
      }, [onCapture]);
    // --- Helper functions to extract data from jsonData
    const extractGraphData = (jsonData) => {
      const graph = jsonData.graph;
      const extractedGraphData = {};
      Object.keys(graph).forEach((key) => {
        extractedGraphData[key] = {
          calls: graph[key].calls.map(([condition, target]) => [
            condition || "",
            target,
          ]),
          file: graph[key].file,
          description: graph[key].description || "No description available",
        };
      });
      return extractedGraphData;
    };
  
    const extractCategories = (jsonData) => {
        const states = jsonData.states || {}; // Ensure states is defined
        const extractedCategories = {};
        const nodeOccurrences = {};
      
        // Track occurrences of each node to identify duplicates
        Object.entries(states).forEach(([stateName, stateData]) => {
          if (!stateData.elements || !Array.isArray(stateData.elements)) {
            console.warn(`⚠️ Warning: Missing or invalid "elements" in state "${stateName}".`);
            return;
          }
      
          stateData.elements.forEach((node) => {
            if (!nodeOccurrences[node]) {
              nodeOccurrences[node] = [];
            }
            nodeOccurrences[node].push(stateName);
          });
        });
      
        // Create a map that stores the final node names (avoid splitting issues)
        const nodeRenamingMap = {};
      
        Object.entries(states).forEach(([stateName, stateData]) => {
          if (!stateData.elements || !Array.isArray(stateData.elements)) return; // Additional safety check
      
          extractedCategories[stateName] = {
            elements: stateData.elements.map((node) => {
              const occurrences = nodeOccurrences[node];
              if (occurrences && occurrences.length > 1) {
                const newName = `${node}_${stateName}`;
                nodeRenamingMap[newName] = node; // Store the original node name
                return newName;
              }
              nodeRenamingMap[node] = node; // Track non-modified names
              return node;
            }),
          };
        });
      
        return { extractedCategories, nodeRenamingMap };
      };
      
      
  
    const { extractedCategories: categories, nodeRenamingMap } = extractCategories(jsonData);

    const graphData = extractGraphData(jsonData);
    console.log("Extracted Categories:", categories);
    console.log("Extracted Graph Data:", graphData);
  
    useEffect(() => {
        // STEP 1: Create group nodes (bounding boxes) and child nodes (inner grid layout).
        const groupNodes = [];
        const childNodes = [];
        const layoutData = {};
      
        Object.entries(categories).forEach(([categoryName, { elements }]) => {
          if (!elements || !Array.isArray(elements)) {
            console.warn(`⚠️ Warning: Missing or invalid elements for category "${categoryName}".`);
            return;
          }
      
          const groupId = `group-${categoryName}`;
          const maxColumns = Math.min(6, elements.length);
          const numRows = Math.ceil(elements.length / maxColumns);
          const childWidth = 100;
          const childHeight = 100;
          const spacingX = 30;
          const spacingY = 30;
          const padding = 70;
          const gridWidth = maxColumns * childWidth + (maxColumns - 1) * spacingX;
          const gridHeight = numRows * childHeight + (numRows - 1) * spacingY;
          const groupWidth = gridWidth + 2 * padding;
          const groupHeight = gridHeight + 2 * padding;
      
          const childOffsets = elements.map((_, idx) => {
            const col = idx % maxColumns;
            const row = Math.floor(idx / maxColumns);
            return {
              offsetX: padding + col * (childWidth + spacingX),
              offsetY: padding + row * (childHeight + spacingY),
            };
          });
          layoutData[groupId] = { childOffsets, width: groupWidth, height: groupHeight };
      
          groupNodes.push({
            id: groupId,
            type: "group",
            position: { x: 0, y: 0 },
            style: {
              width: groupWidth,
              height: groupHeight,
              border: `2px dashed ${theme.groupBorder}`,
              background: theme.groupBackground,
              borderRadius: "12px",
              padding: "8px",
            },
            data: { label: categoryName },
            draggable: true,
          });
          elements.forEach((node, idx) => {
            const originalNodeId = nodeRenamingMap[node]; // Get the true original node name
            if (!graphData[originalNodeId]) {
                console.warn(`⚠️ Warning: Node "${originalNodeId}" not found.`);
                return;
            }

            const file = graphData[originalNodeId].file;
            const nodeType = graphData[originalNodeId].calls.length > 0 ? "function" : "procedure";
            const offset = childOffsets[idx];
            childNodes.push({
              id: node,
              parentId: groupId,
              extent: "parent",
              // Position relative to group; will update to absolute later.
              position: { x: offset.offsetX, y: offset.offsetY },
              draggable: true,
              type: "function",
              data: {
                label: originalNodeId,
                file,
                cat: nodeType,
                description: graphData[originalNodeId].description,
              },
            });
          });
        });
    
        // STEP 2: Outer Layout – Arrange group nodes vertically.
        // Fix x-coordinate for all groups.
        const fixedX = 100;
        let currentY = 50;
        const verticalSpacing = 30;
        groupNodes.forEach((groupNode) => {
          const { height } = layoutData[groupNode.id];
          groupNode.position = { x: fixedX, y: currentY };
          currentY += height + verticalSpacing;
        });
    
        // STEP 3: Update each child node’s absolute position based on its group’s position.
        groupNodes.forEach((groupNode) => {
          const { childOffsets } = layoutData[groupNode.id];
          const children = childNodes.filter((child) => child.parentId === groupNode.id);
          children.forEach((child, index) => {
            const offset = childOffsets[index];
            child.position = {
              x: groupNode.position.x + offset.offsetX,
              y: groupNode.position.y + offset.offsetY,
            };
          });
        });
    
        // STEP 4: Create edges between consecutive state groups.
        const consecutiveStateEdges = [];
        for (let i = 0; i < groupNodes.length - 1; i++) {
          consecutiveStateEdges.push({
            id: `edge-${groupNodes[i].id}-${groupNodes[i + 1].id}`,
            source: groupNodes[i].id,
            target: groupNodes[i + 1].id,
            animated: true,
            type: "straight",
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20,
              color: "red",
            },
            style: { stroke: theme.edgeColor, strokeWidth: 1 },
            labelStyle: { fill: theme.textColor, fontSize: "10px" },
          });
        }
    
        // STEP 5: (Optional) Recreate call edges between inner nodes.
        const callEdges = [];
        
        Object.entries(graphData).forEach(([source, data]) => {
        data.calls.forEach(([condition, target]) => {
            // Get the possible IDs for the source and target from categories.
            const sourceOccurrences = categories[source]?.elements || [source];
            const targetOccurrences = categories[target]?.elements || [target];
            sourceOccurrences.forEach((sourceId) => {
            targetOccurrences.forEach((targetId) => {
                // Only add the edge if both nodes exist among the child nodes.
                if (
                childNodes.find((n) => n.id === sourceId) &&
                childNodes.find((n) => n.id === targetId)
                ) {
                callEdges.push({
                    id: `edge-${sourceId}-${targetId}`,
                    source: sourceId,
                    target: targetId,
                    animated: false,
                    type: "straight", // or "straight", "default", etc.
                    markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 20,
                    height: 20,
                    color: "red",
                    },
                    label: condition,
                    style: { stroke: theme.edgeColor, strokeWidth: 1 },
                    labelStyle: { fill: theme.textColor, fontSize: "10px" },
                });
                }
            });
            });
        });
        });

    
        // Combine nodes and edges.
        const allNodes = [...groupNodes, ...childNodes];
        const allEdges = [...consecutiveStateEdges, ...callEdges];
        console.log("✅ Created Nodes:", allNodes);
        console.log("✅ Created Edges:", allEdges);
        setNodes(allNodes);
        setEdges(allEdges);
      }, [jsonData]);
    
      // Highlight edges connected to a clicked node.
      const handleNodeClick = (event, node) => {
        setEdges((eds) =>
          eds.map((edge) => {
            // Check if this edge connects two state groups:
            const isStateEdge =
              edge.source.startsWith("group-") && edge.target.startsWith("group-");
            
            // If it's a state edge, always keep it visible.
            if (isStateEdge) {
              return {
                ...edge,
                hidden: false,
                style: { stroke: theme.edgeColor, strokeWidth: 1 },
              };
            }
            
            // Otherwise, only show edges connected to the clicked node.
            const isConnected = edge.source === node.id || edge.target === node.id;
            return {
              ...edge,
              hidden: !isConnected,
              style: {
                stroke: isConnected ? "red" : theme.edgeColor,
                strokeWidth: isConnected ? 2 : 1,
              },
            };
          })
        );
      };
      
  
    return (
      <ReactFlowProvider>
        <div >
        <div alignItems="center" style={{ width: "50vw", height: "150vh", backgroundColor: theme.background }}>
          <ReactFlow
          ref={containerRef}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
           
            onNodeClick={handleNodeClick}
            nodeTypes={nodeTypes}
            fitView
            style={{ backgroundColor: theme.background }}
            
          >
            <Controls style={{ color: "#333" }} />
            <Background color="#DDD" gap={16} />
          </ReactFlow>
          
        </div>
        
        </div>
      </ReactFlowProvider>
    );
  };
  
  export default GraphVisualization;









