import React from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";
import AdminSidebar from "../../components/AdminDashboard/AdminSidebar";

const AdminDashboard = () => {
  return (
    <Flex>
      <AdminSidebar />
      <Box flex="1" bg="vscode.background" p={8} textAlign="center">
        <Heading>Welcome to the Dashboard</Heading>
        {/* Main content goes here */}
      </Box>
    </Flex>
  );
};

export default AdminDashboard;

