import React, { useState } from "react";
import {
  Box,
  Container,
  Text,
  Link,
  Stack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
} from "@chakra-ui/react";
import { resetCookieConsentValue } from "react-cookie-consent";

const Footer = () => {
  const [isCookieModalOpen, setCookieModalOpen] = useState(false);
  const [functionalCookies, setFunctionalCookies] = useState(true);
  const [analyticsCookies, setAnalyticsCookies] = useState(false);
  const [marketingCookies, setMarketingCookies] = useState(false);

  const handleSavePreferences = () => {
    // Simulate saving preferences (You would actually set cookies here)
    console.log("Functional Cookies:", functionalCookies);
    console.log("Analytics Cookies:", analyticsCookies);
    console.log("Marketing Cookies:", marketingCookies);

    // Reset cookie consent so the user can see the banner again if needed
    resetCookieConsentValue();

    setCookieModalOpen(false);
  };
  return (
    <Box as="footer" bg="#0A81B4" color="gray.200"> {/* Xelerit Medium Blue */}
      <Container maxW="container.2xl">
        <Stack
          direction={"row"}
          justify="space-between"
          align="center"
        >
          {/* Company Name */}
          <Text fontSize="sm">
            © {new Date().getFullYear()} Xelerit-Robotics. All rights reserved.
          </Text>

          <Text fontSize="sm">
            By using Xelerit you accept our{" "}
            <Link href="/terms" size={"xs"} fontSize="sm" _hover={{ color: "#08BEF7" }}>
              Terms of Use
            </Link>{" "}
            and{" "}
            <Link href="/privacy" size={"xs"} fontSize="sm" _hover={{ color: "#08BEF7" }}>
              Privacy / Cookie Policy
            </Link>.
          </Text>
          <Button
            onClick={() => setCookieModalOpen(true)}
            color="white"
            fontSize="sm"
            size={"xs"}
            bg=""
            _hover={{ color: "vscode.foreground" }}
          >
            Cookie Preferences
          </Button>
        </Stack>
      </Container>
      <Modal isOpen={isCookieModalOpen} onClose={() => setCookieModalOpen(false)} isCentered>
        <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(5px)" />
        <ModalContent bg="#252526" color="white">
          <ModalHeader>Manage Cookie Preferences</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="sm" mb={3}>
              Select which types of cookies you want to allow:
            </Text>
            <Stack spacing={3}>
              <Checkbox isChecked={functionalCookies} onChange={(e) => setFunctionalCookies(e.target.checked)}>
                Functional Cookies (Required)
              </Checkbox>
              <Checkbox isChecked={analyticsCookies} onChange={(e) => setAnalyticsCookies(e.target.checked)}>
                Analytics Cookies
              </Checkbox>
              <Checkbox isChecked={marketingCookies} onChange={(e) => setMarketingCookies(e.target.checked)}>
                Marketing Cookies
              </Checkbox>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" color="white" mr={3} onClick={() => setCookieModalOpen(false)}>
              Cancel
            </Button>
            <Button bg="#08BEF7" color="white" onClick={handleSavePreferences}>
              Save Preferences
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Footer;
