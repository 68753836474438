import React, { useEffect, useRef, useState } from "react";
import {
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Input,
  HStack,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { FaSync } from "react-icons/fa";
import { EditorState } from "@codemirror/state";
import { EditorView } from "@codemirror/view";
import { abb } from "../syntaxes/abbsyntax.js"; // Ensure correct import
import { basicSetup, minimalSetup } from '@uiw/codemirror-extensions-basic-setup';
import { darcula } from '@uiw/codemirror-themes-all';

const CodeEditor = ({ files, onFileChange }) => {
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [syncCount, setSyncCount] = useState(0);
  const editorRefs = useRef([]);
  
  const myCustomTheme = EditorView.theme({
    // The root editor element:
    "&": {
      color: "#d4d4d4",           // Foreground (text) color, e.g., from vscode.foreground
      backgroundColor: "vscode.background",
      minHeight: "700px",   // Background color, e.g., from vscode.background
    },
    // The editor content area:
    ".cm-content": {
      caretColor: "#fffffff",       
      fontFamily: "monospace",
      fontSize: "14px",
    },
    // The gutters (line numbers, etc.):
    ".cm-gutters": {
      backgroundColor: "#252526",   // e.g., from vscode.sidebar
      color: "#999999",
      border: "none",
    },
    // Style the selected text (for both CodeMirror's selection and native selection)
    ".cm-selectionBackground, ::selection": {
      backgroundColor: "#334155", 
    },
    "&.cm-focused ::selection": {
      backgroundColor: "#ffffff", 
    },
    ".cm-activeLine": {
      backgroundColor: "#2c313c", 
    },
    ".cm-scroller": {overflow: "auto"},


  }, { dark: true });
  
  
  const messages = [
    "Generating initial code",
    "Running on simulation",
    `Found ${Math.floor(Math.random() * 10)} errors`,
    "Correcting code",
    "Generating code",
  ];

  useEffect(() => {
    if (files.length > 0) {
      files.forEach((file, index) => {
        if (editorRefs.current[index] && !editorRefs.current[index].view) {
          const state = EditorState.create({
            doc: file.content,
            extensions: [ abb(), myCustomTheme, basicSetup({
              foldGutter: false,
              dropCursor: false,
              allowMultipleSelections: false,
              indentOnInput: false,
            })],
          });

          editorRefs.current[index].view = new EditorView({
            state,
            parent: editorRefs.current[index],
          });
        }
      });
    }
  }, [files]);

  const handleAddFile = () => {
    const newFile = {
      fileName: `NewFile${files.length + 1}.erp`,
      content: "// New file content",
    };
    onFileChange([...files, newFile]);
  };

  

  return (
    <Box width="100%" height="100vh" padding={4}>
      <VStack flex="1" height="100%" spacing={8}>

        {isLoading ? (
          <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" padding={6}>
            <VStack>
              <Spinner size="xl" color="blue.500" />
              <Box marginTop={4}>{loadingMessage}</Box>
            </VStack>
          </Box>
        ) : (
          <Tabs
          onChange={(index) => setCurrentFileIndex(index)}
          index={currentFileIndex}
          width="100%"
          height="calc(100vh - 100px)"
          overflow="hidden"
          display="flex "
          flexDirection="column"
        >
          <Box position="sticky" top="0" zIndex="1" overflowX="auto" maxWidth="100%" >
            <TabList>
              {files.map((file, index) => (
                <Tab key={index} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" maxWidth="200px" bg="vscode.sidebar" color="orange">
                  {file.fileName}
                </Tab>
              ))}
            </TabList>
          </Box>

            <TabPanels flex="1" overflowY="auto">
              {files.map((file, index) => (
                <TabPanel key={index} height="100%">
                  <VStack width="100%" height="100%" align="stretch">
                    <HStack marginBottom={2} spacing={4}>
                      <Input
                        value={file.fileName}
                        onChange={(e) => {
                          const updatedFile = { ...file, fileName: e.target.value };
                          onFileChange(files.map((f, i) => (i === index ? updatedFile : f)));
                        }}
                        size="sm"
                        placeholder="File Name"
                      />
                    </HStack>
                    <Box
                      ref={(el) => (editorRefs.current[index] = el)}
                      flex="1"
                      width="100%"
                      height="100%"
                      
                    />
                  </VStack>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        )}
      </VStack>
    </Box>
  );
};

export default CodeEditor;
