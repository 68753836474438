import React, { useState, useEffect, useRef } from "react";
import ChatPanel from "../../components/panels/chatPanel";
import SidebarPremium from "../../components/Layout/SidebarPremium";
import FlowDiagramPanel from "../../components/panels/flowDiagramPanel";
import { Flex, Box, HStack, VStack, Select, Heading, Text, Button, useDisclosure, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input
} from "@chakra-ui/react";
import { useTheme } from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/react"; 
import ReactFlow, { useNodesState, useEdgesState, MarkerType } from "reactflow";
import { generateFlowDiagram } from "../../api/flowdiagram";
import CodeEditor from "../../components/CodeEditor";
import GroupNodeDetails from "../../components/panels/groupNodePanel";
import TranslatePanel from "../../components/panels/translatePanel";
import Footer from "../../components/Layout/Footer";
import FeedbackComponent from "../../components/Feedback";
import { MultiSelect, useMultiSelect } from 'chakra-multiselect'
import { sendRobotRequest } from "../../api/feedback";
import FilesPanel from "../../components/panels/filesPanel";
import { set } from "react-hook-form";
import { generateCode } from "../../api/code";
import JoyRideTour from "../../components/UserDemo/ComponentsTour";
import CookieBanner from "../../components/CookieBanner";
import HelpCarouselModal from "../../components/modals/HelpCarouselModal";
import { useAuth } from "../../hooks/AuthProvider";
import ProjectPopup from "../../components/ProjectPopUp";
import { getProjectById } from "../../api/projects";

export const handleGenerateCode = (data) => {
  console.log("Code generation started...");
  console.log("Data:", data);
  // Generate code here
}

const CodeEditorPage = () => {
  const theme = useTheme();
  const { user, logOut } = useAuth();
  const [activePanel, setActivePanel] = useState("flowdiagram");
  const [panelWidths, setPanelWidths] = useState({
    chat: 100,
    profile: 50,
    flowdiagram: 100,
    translator: 100,
    files:20,
  });
  const { value, options, onChange } = useMultiSelect({
    value: [],
    options: ["kuka", "abb", "estun"],
  })
  const [showCodeEditor, setShowCodeEditor] = useState(false);

  const [codeFiles, setCodeFiles] = useState([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [flowLoading, setFlowLoading] = useState(false);
  const [currentPanelWidth, setCurrentPanelWidth] = useState(panelWidths[activePanel]);
  const [selectedRobotBrand, setSelectedRobotBrand] = useState("abb");
  const [questionCount, setQuestionCount] = useState(0); // Add state for question count
  const [translateCount, setTranslateCount] = useState(0); // Add state for translation count
  const [nodeClickCount, setClickNodeCount] = useState(0); // Add state for translation count
  const [files, setFiles] = useState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedGroupNode, setSelectedGroupNode] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isRequestOpen, onOpen: onRequestOpen, onClose: onRequestClose } = useDisclosure();
  const { isOpen: isCodeGenerationOpen, onOpen: onCodeGenerationOpen , onClose: onCodeGenerationClose } = useDisclosure();
  const [selectedLanguageGeneration, setSelectedLanguageGeneration] = useState("");
  const [codegenerationLoading, setCodegenerationLoading] = useState(false);
  const [codeGenerationData, setCodeGenerationData] = useState({});
  const { isOpen: isHelpOpen, onOpen: onOpenHelp, onClose: onCloseHelp } = useDisclosure();
  const [showUploadRequirements, setShowUploadRequirements] = useState(false);

  const [isMobile] = useMediaQuery("(max-width: 768px)"); // Check if screen is small

  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
  const { isOpen: isProjectPopupOpen, onOpen: onProjectPopupOpen, onClose: onProjectPopupClose } = useDisclosure();
  const [projectData, setProjectData] = useState({
    plc_brand: "",
    robot_brand: "",
  });

  useEffect(() => {
    onProjectPopupOpen(); // Automatically open the project popup on mount
  }, [onProjectPopupOpen]);

  const fetchProjectDetails = async (projectId) => {
    try {
      console.log("Fetching project details for project ID:", projectId);
      const response = await getProjectById(projectId);
      console.log("Fetched project details:", response);
      setProjectData({
        plcManufacturer: response.plc_brand || "siemens",
        robotBrand: response.robot_brand || "estun",
      });
      await initChat({ robot_brand_id: response.robot_brand || "estun" });
      setShowUploadRequirements(true); // Show the upload requirements component
    } catch (error) {
      console.error("Error fetching project details:", error);
    }
  };

  const handleSelectProject = (projectId) => {
    console.log("Project selected:", projectId);
    fetchProjectDetails(projectId);
    onProjectPopupClose();
  };
  const handleProjectCreated = () => {
    console.log("Project created");
    setShowUploadRequirements(true); // Show the upload requirements component after creating a new project
  };


  const [parsedUser, setParsedUser] = useState(() => {
    try {
      // Parse user only if it is a string
      return typeof user === "string" ? JSON.parse(user) : user || {};
    } catch (error) {
      console.error("Error parsing user:", error);
      return {};
    }
  });
  const  [robotRequest, setRobotRequest] = useState("");
  const [runTour, setRunTour] = useState(true);
  const [tourStepIndex, setTourStepIndex] = useState(0);

  // Define the steps for the guided tour
  const tourSteps = [
    {
      target: ".tour-sidebar", // CSS selector of the sidebar container
      content: "This is the sidebar. Use it to switch between panels.",
      placement: "right",
      disableBeacon: true,
      disableOverlayClose: true,
    },
    {
      target: ".tour-chat-panel", // CSS selector of the chat panel container
      content: "Here is your chat panel where you can ask questions.",
      placement: "bottom",
      disableOverlayClose: true,
    },
    {
      target: ".tour-flow-diagram", // CSS selector of the flow diagram panel container
      content: "This is the flow diagram panel showing your process flows. You can generate the robot code from here.",
      placement: "bottom",
      disableOverlayClose: true
    }
  ];

  useEffect(() => {
    if (isMobile) {
      setSidebarOpen(false); // Hide sidebar on mobile
      setCurrentPanelWidth(100); // Use full width for main content
    }
  }, [isMobile]);

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };



  useEffect(() => {
    console.log("Selected Robot Brand:", selectedRobotBrand);
    
  }, [selectedRobotBrand]);
  useEffect(() => {
    console.log("Parsed User:", parsedUser);
  }, [parsedUser]);

  useEffect(() => {
    if (activePanel) {
      setCurrentPanelWidth(panelWidths[activePanel] || 50);
    }
  }, [activePanel, panelWidths]);

  const handleRobotBrandChange = (event) => {
    setSelectedRobotBrand(event.target.value);
  };

  const generateFlowDiagram = async () => {
    setFlowLoading(true);
    console.log("Generated Flow Diagram:", nodes, edges);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setFlowLoading(false);
  };

  const onGroupNodeSelect = (node, childNodes) => {
    console.log("Group node selected:", node, childNodes);
    updatePanelWidth("flowdiagram", 40);

    // Set the selected group node and its child nodes
    setSelectedGroupNode({ node, childNodes });

    setClickNodeCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount >= 5) {
        onOpen(); // Open the feedback modal after 3 questions
        return 0; // Reset the count
      }
      return newCount;
    });
  };

  const updatePanelWidth = (panel, width) => {
    setPanelWidths((prevWidths) => ({
      ...prevWidths,
      [panel]: width,
    }));
    setCurrentPanelWidth(width);
  };

  const handlePanelSelect = (panel) => {
    setActivePanel(panel);
    if (panel === "translator" || panel === "chat") {
      setSelectedGroupNode(null); // Show CodeEditor for files panel
    }
    if (panel === "files" ) {
      setShowCodeEditor(true);
      setSelectedGroupNode(null);
      
    }
    else{
      setShowCodeEditor(false);
      updatePanelWidth("flowdiagram", 100);

    }
    if (panel === "help") {
      onOpenHelp(); // Open the help modal
    }
  };

  const handleCloseHelp = () => {
    onCloseHelp(); // Close the modal
    setActivePanel("flowdiagram"); // Reactivate flow diagram
  };
  const handleGenerateCode = (data) => {
    console.log("Code generation with data...");
    console.log("Data:", data);
    setCodeGenerationData(data);
    // Generate code here
    onCodeGenerationOpen();
  }

  const handleCodeGenerationSubmit = async () => {
    setCodegenerationLoading(true);
    console.log("Code generation started...");
    console.log("Selected Language:", selectedLanguageGeneration);
    const payload = {
      state: {
        data: {
          // Use a description from your node's data. Here we use description if available, otherwise the label.
          description: codeGenerationData.state.data.description || codeGenerationData.label || "",
          // If your node data contains arrays for inputs, outputs, and traj, use them here.
          inputs: codeGenerationData.state.data.inputs || [],
          outputs: codeGenerationData.state.data.outputs || [],
          traj: codeGenerationData.state.data.traj || []
        },
      },
      language: selectedLanguageGeneration, // The language selected by the user.
    };
    console.log("Payload:", payload);
    const response = await generateCode(payload);
    console.log("Response:", response);
    const newFileName = codeGenerationData.state.data.name;
    const newFile = { fileName: newFileName, content: response.code };

    // Filter out any file that already has the same name
    setFiles((prevFiles) => {
      const filteredFiles = prevFiles.filter((file) => file.fileName !== newFileName);
      return [...filteredFiles, newFile];
    });
    setCodegenerationLoading(false);
    onCodeGenerationClose();
    setSelectedGroupNode(null);
    setActivePanel("files");
    setShowCodeEditor(true);
    
  };


  const handleQuestionAsked = async () => {
    setQuestionCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount >= 3) {
        return 0; // Reset the count
      }
      return newCount;
    });

    if (questionCount + 1 >= 3) {
      // wait 3 sec
      await new Promise((resolve) => setTimeout(resolve, 1000));
      onOpen(); // Open the feedback modal after 3 questions
    }
  };

  const handleTranslateClicked = () => {
    setTranslateCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount >= 2) {
        onOpen(); // Open the feedback modal after 3 questions
        return 0; // Reset the count
      }
      return newCount;
    });
  };

  // const handleNodeClicked = () => {
  //   setClickNodeCount((prevCount) => {
  //     const newCount = prevCount + 1;
  //     if (newCount >= 2) {
  //       onOpen(); // Open the feedback modal after 3 questions
  //       return 0; // Reset the count
  //     }
  //     return newCount;
  //   });
  // };

  const handleSendRequest = async () => {
    const payload = {
      user_id: parsedUser.id,
      robot_brand: robotRequest,
    };
  
    console.log("Request Payload:", payload);
  
    try {
      const response = await sendRobotRequest(payload);
      console.log("Response:", response);
    } catch (error) {
      console.error("Error sending request:", error);
    }
    finally
    {
      onRequestClose();
    }
  };

  const handleAddFile = () => {
    const newFile = { fileName: `NewProgram${files.length + 1}.erd`, content: "hello", isOpen: true };
    setFiles([...files, newFile]);
  };
  const handleTourCallback = (data) => {
    const { type } = data;
    console.log("Tour Callback:", data);
    if (type === 'tour:end') {
      // Stop the tour
      setRunTour(false);
      setActivePanel("chat");
      return;
    }
    // Other callback handling...
  };

  const handleSelectFile = (index) => {
    const updatedFiles = files.map((file, i) => ({
      ...file,
      isOpen: i === index || file.isOpen,
    }));
    setFiles(updatedFiles);
  };

  const handleCloseFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  const handleFileChange = (updatedFile) => {
   
    console.log ("updatedFile", updatedFile)
    setFiles(updatedFile);
  };

  return (
    <Flex direction="column" height="100vh">
      {/* Joyride component for the guided tour */}
      
      <CookieBanner />
      
      

      <FeedbackComponent isOpen={isOpen} onClose={onClose} userId={parsedUser.id} />
        {isMobile && (
          <Box bg="vscode.sidebar" padding={3} color="white">
            
            <Button size="sm" onClick={() => alert("Please check from laptop for code generation")}>
            ☰
            </Button>
          </Box>
        )}

        <Flex flex="1" direction={isMobile ? "column" : "row"} overflow="hidden">
          {/* Sidebar: Hide on Mobile */}
        {!isMobile || sidebarOpen ? (
          <Box className="tour-sidebar" padding={2} display={isMobile ? "none" : "block"}>
            <SidebarPremium onPanelSelect={handlePanelSelect} />
          </Box>
        ) : null}
        {activePanel && (
          <Box
            width={`${currentPanelWidth}%`}
            bg={theme.colors.vscode.background}
            color="white"
            overflowY="auto"
            boxShadow="lg"
            height="97vh"
          >
            {activePanel === "flowdiagram" && (
              <Box className="tour-flow-diagram">
              <FlowDiagramPanel
                nodes={nodes}
                edges={edges}
                setNodes={setNodes}
                setEdges={setEdges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                generateFlowDiagram={generateFlowDiagram}
                loading={flowLoading}
                onNodeSelect={setSelectedNode}
                onGroupNodeSelect={onGroupNodeSelect}
                onGenerateCode={handleGenerateCode}
              />
              </Box>
            )}
            {activePanel === "files" && (
              <Box className=".tour-code">
              <FilesPanel
              files={files}
              onAddFile={handleAddFile}
              onSelectFile={handleSelectFile}
              onCloseFile={handleCloseFile}
          />
          </Box>
          )}
            
            {activePanel === "help" && <HelpCarouselModal isOpen={isHelpOpen} onClose={handleCloseHelp} />}
          </Box>

          
        )}

        <Box flex="1" bg="vscode.background" height="97vh">
          {selectedGroupNode ? (
            <GroupNodeDetails
              groupNode={selectedGroupNode.node}
              childNodes={selectedGroupNode.childNodes}
              onUpdate={(updatedGroupNode, updatedChildNodes) => {
                setNodes((prevNodes) =>
                  prevNodes.map((node) =>
                    node.id === updatedGroupNode.id
                      ? updatedGroupNode
                      : updatedChildNodes.find((child) => child.id === node.id) || node
                  )
                );
              }}
              onClose={() => {
                setSelectedGroupNode(null); // Close the panel when done
                updatePanelWidth("flowdiagram", 100); 
                if (files.length > 0) {
                  setShowCodeEditor(true); // Show the code editor panel
                  updatePanelWidth("flowdiagram", 50); // Reset the width of the flow diagram panel
                }
              }}
            />
          ) : showCodeEditor ? ( <CodeEditor files={files} onFileChange={handleFileChange} /> ) :
          null
        }
        </Box>
      </Flex>

      {/* Footer */}
      {!isMobile && (
        <Box>
          <Footer />
        </Box>
      )}

      <Modal isOpen={isRequestOpen} onClose={onRequestClose} isCentered>
        <ModalOverlay  bg="blackAlpha.600" backdropFilter="blur(5px)" />
        <ModalContent bg="vscode.sidebar">
          <ModalHeader>Request Another Robot</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Provide details about the robot you want to request.</Text>
            <Select placeholder="Select Robot Brand" mt={4} mb={4} onChange={(e) => setRobotRequest(e.target.value)}>
                                  {["Fanuc", "Yaskawa", "Universal Robots", "Epson", "Staubli", "Comau", "Omron", "Nachi", "Denso", "Kawasaki", "Doosan ", "Hanwha ", "Schunk", "Festo", "Techman", "Reis"]
                      .sort().map((brand) => (
                      <option key={brand} value={brand}>
                        {brand.charAt(0).toUpperCase() + brand.slice(1)}
                      </option>
                    ))}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" color="white" mr={3} onClick={onRequestClose}>
              Close
            </Button>
            <Button bgColor="xelerit.lightBlue" color="white" onClick={handleSendRequest}>Submit Request</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isCodeGenerationOpen} onClose={onCodeGenerationClose} isCentered>
        <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(5px)" />
        <ModalContent bg="vscode.sidebar">
          <ModalHeader>Select Code Generation Language</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              placeholder="Select language"
              value={selectedLanguageGeneration}
              onChange={(e) => setSelectedLanguageGeneration(e.target.value)}
            >
              <option value="abb">ABB</option>
              <option value="kuka">KUKA</option>
              {/* Add more options as needed */}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" color="white" mr={3} onClick={onCodeGenerationClose}>
              Cancel
            </Button>
            <Button
              bgColor="xelerit.lightBlue"
              color="white"
              onClick={handleCodeGenerationSubmit}
            >
              {codegenerationLoading ? "Generating..." : "Submit"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {codegenerationLoading && (
        <Box position="absolute" top="10px" right="10px">
          Loading...
        </Box>
      )}

<ProjectPopup isOpen={isProjectPopupOpen} onClose={onProjectPopupClose} onSelectProject={handleSelectProject} onProjectCreated={handleProjectCreated} />


    </Flex>
  );
};

export default CodeEditorPage;
