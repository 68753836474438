import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { message_and_response_stream } from "../../api/message_chat_bot";
import { Flex, Box, useMediaQuery } from "@chakra-ui/react";
import MessageInput from "../Chat/MessageInput";
import InitialMessagePrompt from "../Chat/InitialMessagePrompt";
import MessageList from "../Chat/MessageList";

const ChatPanel = ({ robotBrand, onQuestionAsked, userId }) => {
  const [conversationId, setConversationId] = useState(uuidv4());
  const [isFirstMessageSent, setIsFirstMessageSent] = useState(false);
  const [predefinedQuestions, setPredefinedQuestions] = useState([]);
  const text = "Start by typing your question or choose one below!";
  const letters = text.split("");

  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const [isMobile] = useMediaQuery("(max-width: 768px)"); // Detect mobile screens

  useEffect(() => {
    setConversationId(uuidv4());
    setIsFirstMessageSent(false);
    setMessages([]);
  }, [robotBrand]);

  useEffect(() => {
    switch (robotBrand) {
      case "estun":
        setPredefinedQuestions([
          "What does MovJ do?",
          "What is APOS?",
          "What are the parameters of MovCircle?",
          "How can I integrate you into my project?",
        ]);
        break;
      case "abb":
        setPredefinedQuestions([
          "How do I calibrate the robot?",
          "What does MovJ do?",
          "How is robtarget defined?",
          "What is IndAMove, can you make some examples?",
        ]);
        break;
      case "kuka":
        setPredefinedQuestions([
          "What does PTP do?",
          "How can I define a spline?",
          "What are the parameters of CIRC?",
          "How can I integrate you into my project?",
        ]);
        break;
      default:
        setPredefinedQuestions([
          "What does MovJ do?",
          "What is APOS?",
          "What are the parameters of MovCircle?",
          "How can I integrate you into my project?",
        ]);
        break;
    }
  }, [robotBrand]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = async (message) => {
    const msg = message || userMessage;
    if (!msg.trim()) return;

    const newUserMessage = { sender: "user", content: msg };
    setMessages((prev) => [...prev, newUserMessage]);

    if (!isFirstMessageSent) {
      setIsFirstMessageSent(true);
    }

    const payload = {
      conversation_id: conversationId,
      question: msg,
      top_k: 5,
      robot_brand_id: robotBrand,
      user_id: userId,
    };

    const botMessagePlaceholder = { sender: "bot", content: "", complete: false };
    setMessages((prev) => [...prev, botMessagePlaceholder]);

    try {
      setIsLoading(true);

      await message_and_response_stream(payload, (chunk) => {
        setMessages((prev) => {
          const updatedMessages = [...prev];
          const botMessageIndex = updatedMessages.findIndex(
            (msg) => msg.sender === "bot" && !msg.complete
          );
          if (botMessageIndex !== -1) {
            updatedMessages[botMessageIndex].content += chunk;
          }
          return updatedMessages;
        });
      });

      setMessages((prev) =>
        prev.map((msg) =>
          msg.sender === "bot" && !msg.complete ? { ...msg, complete: true } : msg
        )
      );
    } catch (error) {
      console.error("Error during streaming:", error);
      setMessages((prev) => [
        ...prev.slice(0, -1),
        { sender: "bot", content: "Sorry, something went wrong. Please try again later.", complete: true },
      ]);
    } finally {
      setIsLoading(false);
      setUserMessage("");
      onQuestionAsked();
    }
  };

  if (!isFirstMessageSent) {
    return (
      <Flex justify="center" align="center" height="70%">
        <InitialMessagePrompt
          letters={letters}
          userMessage={userMessage}
          setUserMessage={setUserMessage}
          handleSendMessage={handleSendMessage}
          isLoading={isLoading}
          predefinedQuestions={predefinedQuestions}
        />
      </Flex>
    );
  }

  return (
    <Flex height="100%" justify="center" align="center">
      <Box
        width={{ base: "100%", md: "700px", lg: "1200px" }} // Adjust width for small screens
        position="relative"
        padding={isMobile ? "0px" : "0"} // Add padding for better spacing
      >
        {/* Chat Message List */}
        <Flex
          height={isMobile ? "75vh" : "90vh"} // Adjust height on mobile
          maxHeight={isMobile ? "75vh" : "90vh"}
          overflowY="auto"
          borderRadius="md"
          shadow="inner"
          ref={chatContainerRef}
          direction="column"
          mb={isMobile ? 2 : 10} // Reduce margin on mobile
          paddingBottom={isMobile ? "60px" : "80px"} // Adjust spacing
        >
          <MessageList messages={messages} isLoading={isLoading} />
        </Flex>

        {/* Message Input Box */}
        <Box
          position="absolute"
          bottom={isMobile ? "10px" : "80px"}
          left="50%"
          transform="translateX(-50%)"
          width={isMobile ? "95%" : "100%"} // Make input full-width on mobile
          padding="10px"
          zIndex="1000"
        >
          <MessageInput
            userMessage={userMessage}
            setUserMessage={setUserMessage}
            handleSendMessage={handleSendMessage}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </Flex>
  );
};

export default ChatPanel;

