import { groupBy, meanBy, countBy } from "lodash";

export const aggregateChatData = (chatLogs) => {
  if (!chatLogs || chatLogs.length === 0) {
    return {
      totalInteractions: 0,
      totalUsers: 0,
      interactionsPerDay: {},
      averageResponseTimePerDay: {},
      robotBrandSplit: {},
      userActivity: {},
      averageResponseTime: 0,
      maxResponseTime: 0,
      minResponseTime: 0,
      mostUsedRobotBrand: "N/A",
      mostFrequentQuestions: {},
      questionsPerDay: {},
    };
  }

  const totalInteractions = chatLogs.length;
  const totalUsers = new Set(chatLogs.map((log) => log.user_id)).size;

  // Group by day
  const interactionsPerDay = groupBy(chatLogs, (log) =>
    new Date(log.timestamp).toISOString().split("T")[0]
  );

  // Average response time per day
  const averageResponseTimePerDay = {};
  Object.entries(interactionsPerDay).forEach(([day, logs]) => {
    averageResponseTimePerDay[day] = meanBy(logs, "response_time") || 0;
  });

  // Robot brand distribution
  const robotBrandSplit = countBy(chatLogs, "robot_brand_id");

  // Response times
  const responseTimes = chatLogs.map((log) => log.response_time);
  const averageResponseTime = meanBy(responseTimes) || 0;
  const maxResponseTime = responseTimes.length > 0 ? Math.max(...responseTimes) : 0;
  const minResponseTime = responseTimes.length > 0 ? Math.min(...responseTimes) : 0;

  // Most used robot brand
  const mostUsedRobotBrand = Object.entries(robotBrandSplit)
    .sort((a, b) => b[1] - a[1])[0]?.[0] || "N/A";

  // Questions per day
  const questionsPerDay = {};
  Object.entries(interactionsPerDay).forEach(([day, logs]) => {
    questionsPerDay[day] = logs.map((log) => ({
      user_id: log.user_id,
      question: log.question,
      response: log.response,
      robot_brand_id: log.robot_brand_id,
    }));
  });

  // User activity count
  const userActivity = countBy(chatLogs, "user_id");

  // Most frequent questions
  const questionCounts = countBy(chatLogs, "question");
  const mostFrequentQuestions = Object.fromEntries(
    Object.entries(questionCounts).sort((a, b) => b[1] - a[1]).slice(0, 10)
  );

  return {
    totalInteractions,
    totalUsers,
    interactionsPerDay,
    averageResponseTimePerDay,
    robotBrandSplit,
    userActivity,
    averageResponseTime,
    maxResponseTime,
    minResponseTime,
    mostUsedRobotBrand,
    mostFrequentQuestions,
    questionsPerDay,
  };
};

