import React, { useEffect, useState } from "react";
import apiClient from "../../api/apiClient";

const TranslationStats = () => {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        fetchLogs();
    }, []);

    const fetchLogs = async () => {
        try {
            const response = await apiClient.get("/state/generation-logs");
            if (response && Array.isArray(response)) {
                setLogs(response);
            } else {
                console.error("Unexpected API response format:", response);
                setLogs([]);
            }
        } catch (error) {
            console.error("Error fetching generation logs:", error);
            setLogs([]);
        }
    };

    return (
        <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "20px", textAlign: "center" }}>
            <h1 style={{ fontSize: "28px", fontWeight: "bold", marginBottom: "20px" }}>Generation Logs</h1>

            <div style={{ overflowX: "auto", }}>
                <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
                    <thead>
                        <tr style={{ background: "#d4d4d4", borderBottom: "2px solid #ddd", color:"black" }}>
                            <th style={{ padding: "10px", textAlign: "left" }}>User ID</th>
                            <th style={{ padding: "10px", textAlign: "left" }}>Description</th>
                            <th style={{ padding: "10px", textAlign: "left" }}>Inputs</th>
                            <th style={{ padding: "10px", textAlign: "left" }}>Outputs</th>
                            <th style={{ padding: "10px", textAlign: "left" }}>Trajectory</th>
                            <th style={{ padding: "10px", textAlign: "left" }}>Language</th>
                            <th style={{ padding: "10px", textAlign: "left" }}>Code</th>
                            <th style={{ padding: "10px", textAlign: "left" }}>Timestamp</th>
                            <th style={{ padding: "10px", textAlign: "left" }}>Response Time (s)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs.length > 0 ? (
                            logs.map((log) => (
                                <tr key={log.id} style={{ borderBottom: "1px solid #ddd" }}>
                                    <td style={{ padding: "10px", minWidth: "100px", wordBreak: "break-word" }}>{log.user_id}</td>
                                    <td style={{ padding: "10px", minWidth: "200px", wordBreak: "break-word", whiteSpace: "normal" }}>{log.description}</td>
                                    <td style={{ padding: "10px", minWidth: "200px", wordBreak: "break-word", whiteSpace: "normal" }}>{log.inputs}</td>
                                    <td style={{ padding: "10px", minWidth: "200px", wordBreak: "break-word", whiteSpace: "normal" }}>{log.outputs}</td>
                                    <td style={{ padding: "10px", minWidth: "200px", wordBreak: "break-word", whiteSpace: "normal" }}>{log.traj}</td>
                                    <td style={{ padding: "10px", minWidth: "100px", wordBreak: "break-word" }}>{log.language}</td>
                                    <td style={{ padding: "10px", minWidth: "200px", wordBreak: "break-word", whiteSpace: "normal" }}>{log.code}</td>
                                    <td style={{ padding: "10px", minWidth: "150px", wordBreak: "break-word" }}>{new Date(log.timestamp).toLocaleString()}</td>
                                    <td style={{ padding: "10px", minWidth: "100px", wordBreak: "break-word" }}>{log.response_time ? log.response_time.toFixed(2) : "N/A"}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="9" style={{ padding: "10px", textAlign: "center" }}>No logs available.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TranslationStats;

