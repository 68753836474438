import React from "react";
import { VStack, HStack, Button, Box, IconButton } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const FilePanel = ({ files, onAddFile, onSelectFile, onCloseFile }) => {
  const handleDelete = (e, index) => {
    e.stopPropagation();
    const file = files[index];
    // Add your condition here
    if (file.fileName !== "protectedFile.txt") {
      onCloseFile(index);
    } else {
      alert("This file cannot be deleted.");
    }
  };

  return (
    <VStack
      w="100%"
      h="100vh"
      bg="vscode.sidebar"
      p={4}
      align="stretch"
      spacing={4}
      overflowY="auto"
      boxShadow="md"
    >
      <Button onClick={onAddFile} backgroundColor="white" size="sm">
        + Add File
      </Button>
      {files.map((file, index) => (
        <HStack
          key={index}
          justify="space-between"
          align="center"
          bg={"vscode.background"}
          p={2}
          borderRadius="md"
          cursor="pointer"
          onClick={() => onSelectFile(index)}
        >
          <Box
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            maxW="160px"
          >
            {file.fileName}
          </Box>
          <IconButton
            icon={<CloseIcon />}
            size="xs"
            onClick={(e) => handleDelete(e, index)}
          />
        </HStack>
      ))}
    </VStack>
  );
};

export default FilePanel;
