import { Heading, Flex, Button } from "@chakra-ui/react";
import React, { memo } from "react";
import { Handle } from "reactflow";

const GroupNode = ({ data, isConnectable }) => {
  return (
    <Flex
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        color: "white",
        padding: "10px",
        borderRadius: "5px",
        width: "900px",
        height: "900px",
        display: "flex",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        justifyContent: "center", // Center horizontally
        cursor: "pointer",
        position: "absolute",
        alignItems: "flex-start", // Align at the top
      }}
    >
      <Heading size="lg" color="white" mt={4}>
        {data.label}
      </Heading>
      <Button
        size="lg"
        width={"200px"}
        backgroundColor="xelerit.orange"
        color="white"
        position="absolute"
        top="-10px"
        right="-10px"
        onClick={(e) => {
          // Stop the click from propagating to the parent node.
          e.stopPropagation();
          if (data.onGenerateCodeClick) {
            console.log("Generate code clicked");
            data.onGenerateCodeClick(data);
          }
        }}
      >
        Generate code ->
      </Button>
      <Handle
        type="source"
        position="bottom"
        id="group-output"
        isConnectable={isConnectable}
        style={{ width: "20px", height: "20px" }}
      />
      <Handle
        type="target"
        position="top"
        id="group-input"
        isConnectable={isConnectable}
        style={{ width: "20px", height: "20px" }}
      />
    </Flex>
  );
};

export default memo(GroupNode);

