import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import * as THREE from 'three';

const Upload3DModel = ({ isOpen, onClose, onUpload }) => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [model, setModel] = useState(null);
  const [isParsing, setIsParsing] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (model) {
      console.log('Model position:', model.position);
     
    }
  }, [model]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const objFile = Array.from(files).find((file) => file.name.endsWith('.obj'));
    const mtlFile = Array.from(files).find((file) => file.name.endsWith('.mtl'));
  
    if (objFile) {
      setIsParsing(true);
      setError('');
  
      if (mtlFile) {
        const mtlLoader = new MTLLoader();
        mtlLoader.load(
          URL.createObjectURL(mtlFile),
          (materials) => {
            materials.preload();
            const objLoader = new OBJLoader();
            objLoader.setMaterials(materials);
            objLoader.load(
              URL.createObjectURL(objFile),
              (loadedModel) => {
                setModel(loadedModel);
                setIsParsing(false);
              },
              undefined,
              (err) => {
                setError('Failed to load .obj model with textures.');
                console.error(err);
                setIsParsing(false);
              }
            );
          },
          undefined,
          (err) => {
            setError('Failed to load .mtl file.');
            console.error(err);
            setIsParsing(false);
          }
        );
      } else {
        // Load .obj without materials if .mtl is missing
        const objLoader = new OBJLoader();
        objLoader.load(
          URL.createObjectURL(objFile),
          (loadedModel) => {
            setModel(loadedModel);
            setIsParsing(false);
          },
          undefined,
          (err) => {
            setError('Failed to load .obj file.');
            console.error(err);
            setIsParsing(false);
          }
        );
      }
    } else {
      setError('Please select a valid .obj file.');
    }
  };
  

  const handleFileUpload = async () => {
   
      try {
        const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        await wait(1000);
       
      } catch (error) {
        console.error('Error uploading 3D model:', error);
      } finally {
        setIsUploading(false);
        onClose();
        onUpload(); // Pass updated data to the parent component
      }
  };

  const ModelPreview = ({ model }) => {
    const { camera } = useThree();

    useEffect(() => {
      if (model) {
        // Calculate bounding box
        const box = new THREE.Box3().setFromObject(model);
        const size = new THREE.Vector3();
        box.getSize(size);

        // Center the model
        const center = new THREE.Vector3();
        box.getCenter(center);
        model.position.x -= center.x;
        model.position.y -= center.y;
        model.position.z -= center.z;

        // Adjust camera position based on model size
        const maxSize = Math.max(size.x, size.y, size.z);
        const distance = maxSize ; // Adjust the multiplier for more distance if needed
        camera.position.set(0, -distance, 0); // Position camera along the Y-axis for top view
        camera.near = 0.1;
        camera.far = distance * 4; // Ensure far plane covers large models
        camera.updateProjectionMatrix();
        camera.lookAt(0, 0, 0);
      }
    }, [model, camera]);

    return <primitive object={model} />;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(5px)" />
      <ModalContent bg="vscode.sidebar">
        <ModalHeader>Upload 3D Model</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
          <Input type="file" multiple accept=".obj,.mtl" onChange={handleFileChange} />
            {isParsing && (
              <Box mt={4} display="flex" justifyContent="center" alignItems="center">
                <Spinner size="xl" />
                <Text ml={4}>Parsing model...</Text>
              </Box>
            )}
            {error && (
              <Box mt={4} color="red.500">
                {error}
              </Box>
            )}
            {model && !isParsing && (
              <Box
                mt={4}
                h="300px"
                bg="gray.100"
                borderRadius="md"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Canvas>
                  <ambientLight />
                  <pointLight position={[10, 10, 10]} />
                  <OrbitControls />
                  <ModelPreview model={model} />
                </Canvas>
              </Box>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="xelerit.lightBlue"
            color="white"
            mr={3}
            onClick={handleFileUpload}
            isLoading={isUploading}
            isDisabled={isUploading || !model}
          >
            Upload
          </Button>
          <Button bg="white" color="vscode.sidebar" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Upload3DModel;