import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import {marked} from "marked";
// Generate a DOCX document based on your preview data
import {
    Document,
    Paragraph,
    TextRun,
    HeadingLevel,
    Packer,
    ImageRun,
    Table,
  TableRow,
  TableCell,
  WidthType,
  BorderStyle,
  } from "docx";

export const generatePDF = (previewData) => {
  const doc = new jsPDF("p", "mm", "a4");
  
  // Add a centered title
  doc.setFontSize(20);
  doc.text("Project Documentation", 105, 20, { align: "center" });

  // Project Metadata Section
  doc.setFontSize(14);
  let yPos = 40;
  doc.text(`Title: ${previewData.title || "N/A"}`, 20, yPos);
  yPos += 10;
  doc.text(`Robot Brand: ${previewData.meta?.robot_brand || "N/A"}`, 20, yPos);
  yPos += 10;
  doc.text(`PLC Manufacturer: ${previewData.meta?.plc_manufacturer || "N/A"}`, 20, yPos);
  yPos += 10;
  doc.text(`Client: ${previewData.meta?.client || "N/A"}`, 20, yPos);
  yPos += 10;
  doc.text(`Description: ${previewData.meta?.description || "N/A"}`, 20, yPos);
  
  // New section: Folder Structure
  yPos += 20;
  doc.setFontSize(16);
  doc.text("Project Folder Structure", 20, yPos);
  yPos += 10;
  doc.setFontSize(12);
  // Split long text into lines that fit the page width (here, 170mm)
  const folderLines = doc.splitTextToSize(previewData.project_folder || "No folder structure available", 170);
  doc.text(folderLines, 20, yPos);
  yPos += folderLines.length * 7;
  
  // You can add more sections here. For example, if you have a list of processes or graph data,
  // you might consider using jspdf-autotable to format tables or adding images if needed.

  // Save the PDF
  doc.save("documentation.pdf");
};

// Generate Markdown content from your data
// Generate Markdown content from your data
const generateMarkdown = (previewData) => {
    const { title, meta, project_folder } = previewData;
    return `
  # ${title || "Project Title"}
  
  ## Project Metadata
  
  - **Robot Brand:** ${meta?.robot_brand || "N/A"}
  - **PLC Manufacturer:** ${meta?.plc_manufacturer || "N/A"}
  - **Client:** ${meta?.client || "N/A"}
  - **Description:** ${meta?.description || "N/A"}
  
  ## Project Folder Structure
  
  \`\`\`
  ${project_folder || "No folder structure available"}
  \`\`\`
  
  *Generated on ${new Date().toLocaleString()}*
    `;
  };
  
  // Convert the Markdown to HTML
  const markdownToHtml = (markdownContent) => {
    return marked(markdownContent);
  };
  
  // Download the PDF by compiling the HTML (with custom styling) into a PDF
  export const downloadCompiledPdf = async (previewData) => {
    // Check that previewData is valid
    if (!previewData) {
      console.error("No previewData provided!");
      return;
    }
  
    // 1. Generate Markdown and convert to HTML
    const markdownContent = generateMarkdown(previewData);
    const htmlContent = markdownToHtml(markdownContent);
  
    // 2. Create a hidden container with improved styles
    const container = document.createElement("div");
    container.style.width = "800px";
    container.style.padding = "20px";
    container.style.backgroundColor = "white";
    container.style.color = "black";
    container.style.fontFamily = "'Helvetica', sans-serif";
    container.style.position = "absolute";
    container.style.top = "0";
    container.style.left = "-10000px"; // Move off-screen
  
    // Inject custom CSS (Note: Some CSS may be ignored by jsPDF's html() method)
    container.innerHTML = `
      <style>
        h1 { font-size: 28px; margin-bottom: 10px; color: black; }
        h2 { font-size: 22px; margin-top: 20px; color: black; }
        h3, h4, h5, h6 { color: black; }
        p { font-size: 14px; line-height: 1.5; color: black; }
        ul { margin-left: 20px; color: black; }
        pre {
          background-color: #f7f7f7;
          color: black;
          padding: 10px;
          border-radius: 5px;
          overflow: auto;
        }
        code {
          background-color: #f7f7f7;
          color: black;
          padding: 2px 4px;
          border-radius: 3px;
        }
      </style>
      ${htmlContent}
    `;
  
    // Append the container to the body so it renders
    document.body.appendChild(container);
  
    // 3. Use jsPDF's html() method to convert the HTML to a PDF.
    const pdf = new jsPDF("p", "pt", "a4");
    await pdf.html(container, {
      callback: function (doc) {
        doc.save("documentation.pdf");
        // Remove the container after the PDF is generated
        document.body.removeChild(container);
      },
      x: 20,
      y: 20,
      width: 760, // Target width (A4 width in pts minus margins)
      windowWidth: 800, // The width of the HTML content for scaling
    });
  };

  

  export const generateWordDocument = (previewData) => {
    const {
      title,
      meta,
      project_folder,
      treeOutput, // expect this to be a string representing the output of a tree command
      code_image,
      function_process,
      description,
    } = previewData;

    let imageParagraph = [];
    if (code_image) {
        // The data URL is expected to be in the form "data:image/png;base64,...."
        const base64Data = code_image.split(",")[1];
        imageParagraph = [
        new Paragraph({
            children: [
            new ImageRun({
                data: base64Data,
                transformation: {
                width: 500,
                height: 750,
                },
                
            }),
            ],
        }),
        ];
    }

    let treeParagraphs = [];
    console.log("treeOutput:", project_folder);

        if (project_folder && typeof project_folder === "string") {
        // Split on newlines (supports Windows and Unix line breaks)
        const lines = project_folder.split(/\r?\n/);
        
        console.log("lines:", lines);

        // If the string doesn’t contain any newline characters, use the whole string as one paragraph
        if (lines.length === 0 || (lines.length === 1 && lines[0].trim() === "")) {
            treeParagraphs.push(
            new Paragraph({
                text: treeOutput,
                style: "CodeBlock",
            })
            );
        } else {
            treeParagraphs = lines.map((line) =>
            new Paragraph({
                text: line,
                style: "CodeBlock",
            })
            );
        }
        }
    // Group process functions by file.
    const processesByFile = (function_process || []).reduce((acc, process) => {
        const fileName = process.file || "Unknown File";
        if (!acc[fileName]) {
          acc[fileName] = [];
        }
        acc[fileName].push(process);
        return acc;
      }, {});
    
      // 3. Helper function: Create paragraphs for each process
      const createProcessParagraphs = (proc) => {
        const paragraphs = [];
    
        // -- 3.1 Function Name (Heading)
        paragraphs.push(
          new Paragraph({
            text: proc.name,
            heading: HeadingLevel.HEADING_3,
            spacing: { before: 300 },
          })
        );
    
        // -- 3.2 Horizontal divider (using paragraph border)
        paragraphs.push(
          new Paragraph({
            border: {
              bottom: {
                color: "000000",
                space: 1,
                value: BorderStyle.SINGLE,
                size: 6,
              },
            },
          })
        );
    
        // -- 3.3 Description in bold
        paragraphs.push(
          new Paragraph({
            children: [
              new TextRun({ text: "Description: ", bold: true }),
              new TextRun({ text: proc.description }),
            ],
          })
        );
    
        // -- 3.4 Another horizontal divider
        paragraphs.push(
          new Paragraph({
            border: {
              bottom: {
                color: "000000",
                space: 1,
                value: BorderStyle.SINGLE,
                size: 6,
              },
            },
          })
        );
    
        // 5. Inputs table (if any)
    if (proc.inputs && proc.inputs.length > 1) {
        paragraphs.push(
          new Paragraph({
            text: "Inputs",
            heading: HeadingLevel.HEADING_4,
          })
        );
  
        // Single-column table for inputs
        const inputRows = [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({ text: "Inputs", bold: true }),
                ],
              }),
            ],
          }),
        ];
  
        proc.inputs.forEach((inputStr) => {
          inputRows.push(
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph(inputStr)],
                }),
              ],
            })
          );
        });
  
        paragraphs.push(
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: inputRows,
          })
        );
      }
  
      // 6. Outputs table (if any)
      if (proc.outputs && proc.outputs.length > 1) {
        paragraphs.push(
          new Paragraph({
            text: "Outputs",
            heading: HeadingLevel.HEADING_4,
          })
        );
  
        // Single-column table for outputs
        const outputRows = [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({ text: "Outputs", bold: true }),
                ],
              }),
            ],
          }),
        ];
  
        proc.outputs.forEach((outputStr) => {
          outputRows.push(
            new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph(outputStr)],
                }),
              ],
            })
          );
        });
  
        paragraphs.push(
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: outputRows,
          })
        );
      }
  
    
        return paragraphs;
      };
    
      // 4. Build the paragraphs for all processes (grouped by file)
      const processSections = [];
      for (const [fileName, processes] of Object.entries(processesByFile)) {
        // File name as a Heading
        processSections.push(
          new Paragraph({
            text: fileName,
            heading: HeadingLevel.HEADING_2,
            spacing: { before: 300 },
          })
        );
    
        // For each process, add the function block layout
        processes.forEach((proc) => {
          processSections.push(...createProcessParagraphs(proc));
        });
      }
  
    return new Document({
      sections: [
        {
          // Cover Page Section
          children: [
            new Paragraph({
              text: title || "Project Title",
              heading: HeadingLevel.TITLE,
              alignment: "center",
            }),
            new Paragraph({
              text: meta?.client || "Client Name",
              heading: HeadingLevel.HEADING_2,
              alignment: "center",
            }),
            new Paragraph({
              text: `Generated on ${new Date().toLocaleString()}`,
              alignment: "center",
              spacing: { before: 400 },
            }),
            new Paragraph({
                children: [
                  new TextRun({
                    text: `@ Xelerit GmbH | Zurich, Switzerland`,
                    italics: true,
                  }),
                ],
                alignment: "center",
                spacing: { before: 100 },
              }),
              new Paragraph({
                text: "Table of Contents",
                heading: HeadingLevel.HEADING_1,
                spacing: {  before: 500, after: 200 },
              }),
              new Paragraph({
                text: "1. Introduction",
                bullet: { level: 0 },
              }),
              new Paragraph({
                text: "2. Metadata",
                bullet: { level: 0 },
              }),
              new Paragraph({
                text: "3. Project Structure",
                bullet: { level: 0 },
              }),
              new Paragraph({
                text: "4. Architecture",
                bullet: { level: 0 },
              }),
              new Paragraph({
                text: "5. Library",
                bullet: { level: 0 },
              }),
          ],
        },
        
        {
          // Main Content Section
          children: [
            // Introduction
            new Paragraph({
              text: "Introduction",
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              text: "This document outlines the project's metadata, architecture, and folder structure. It highlights key features, design rationale, and deployment details for developers and stakeholders.",
            }),
  
            // Metadata Section
            new Paragraph({
              text: "Metadata",
              heading: HeadingLevel.HEADING_1,
              spacing: { before: 300 },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Robot Brand: ${meta?.robot_brand || "N/A"}`,
                  bold: true,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `PLC Manufacturer: ${meta?.plc_manufacturer || "N/A"}`,
                  bold: true,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Client: ${meta?.client || "N/A"}`,
                  bold: true,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `Description: ${description || "N/A"}`,
                  bold: true,
                }),
              ],
            }),
  
            
            // Project Structure Section
            new Paragraph({
              text: "Project Structure",
              heading: HeadingLevel.HEADING_1,
              spacing: { before: 300 },
            }),
            ...treeParagraphs,
            // Additional Sections
            new Paragraph({
              text: "Architecture",
              heading: HeadingLevel.HEADING_1,
              spacing: { before: 300 },
            }),
            new Paragraph({
              text:
                "This section details the system architecture, describing key modules, their interactions, and design decisions. It covers component integration, data flow, and essential scalability, performance, and security considerations.",
            }),

            ...imageParagraph,

            new Paragraph({
                text: "Library",
                heading: HeadingLevel.HEADING_1,
                spacing: { before: 300 },
              }),
              ...processSections,
  
            // Final Generated Timestamp
            new Paragraph({
              children: [
                new TextRun({
                  text: `Document generated on ${new Date().toLocaleString()}`,
                  italics: true,
                }),
              ],
              spacing: { before: 400 },
            }),
            new Paragraph({
                children: [
                  new TextRun({
                    text: `@ Xelerit GmbH | Zurich, Switzerland`,
                    italics: true,
                  }),
                ],
                spacing: { before: 400 },
              }),
          ],
        },
      ],
    });
  };
  
  export const downloadWordFile = async (previewData) => {
    const doc = generateWordDocument(previewData);
  
    // Convert the document to a Blob
    const blob = await Packer.toBlob(doc);
  
    // Create a URL for the Blob and trigger a download
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "documentation.docx";
    a.click();
    URL.revokeObjectURL(url);
  };
  